import React from 'react'
import PlaceOrderBreadcrums from '../Components/PlaceOrderBreadcrums'


import { breadcrumsorder } from '../Data';
import OrderSummary from '../Components/OrderSummary';
import AccountRegister from '../Components/AccountRegister';
import Shipping from '../Components/Shipping';
function Account() {
    return (
        <div>
            <div className='row'>
                <div className='col-md-8 col-sm-12'>
                    <PlaceOrderBreadcrums breadcrumsorder={breadcrumsorder} />

                   

                </div>
                <div className='col-md-4 col-sm-12'>

                  <OrderSummary />
                </div>
            </div>
        </div>
    )
}

export default Account