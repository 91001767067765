import React from 'react'

import CarouselMulti from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

import 'animate.css';
import { categorybanner } from '../Data';
function CarousalPageCategory() {

    const responsive = {
        desktop: {
          breakpoint: { max: 3000, min: 1200 },
          items: 1,
          slidesToSlide: 1, // Number of slides to scroll
          partialVisibilityGutter: 0, // Space between slides
        },
        tablet: {
          breakpoint: { max: 1200, min: 600 },
          items: 1,
          slidesToSlide: 1, // Number of slides to scroll
          partialVisibilityGutter: 0, // Space between slides
        },
        mobile: {
          breakpoint: { max: 600, min: 0 },
          items: 1,
          slidesToSlide: 1, // Number of slides to scroll
          partialVisibilityGutter: 0, // Space between slides
        },
      };


  return (
    <div className='gtt-category-banner-carousel'>

<CarouselMulti
            responsive={responsive}
            additionalTransfrom={0}
            arrows
            autoPlay
            autoPlaySpeed={5000}
              
            centerMode={false}
            className=''
            containerClass='container-with-dots'
            dotListClass=''
            draggable
            focusOnSelect={false}
            infinite
            itemClass=''
            keyBoardControl
            minimumTouchDrag={80}
            renderButtonGroupOutside={false}
            renderDotsOutside={false}
           
            sliderClass=''
            slidesToSlide={1}
            swipeable
          >
            {categorybanner.map((image, index) => (
              <div key={index} className=' ' >
                   <img
                    className="w-100 p-1"
                    src={image.img_url}
                    alt='category'/>
              </div>
            ))}
          </CarouselMulti>
    </div>
  )
}

export default CarousalPageCategory