import React from 'react'

import Navbar from '../Components/Navbar';

import Headbackground from '../Components/Headbackground';
import CarousalMaster from '../Components/CarousalMaster';
import Adslist from '../Components/Adslist';
import CategorySlide from '../Components/CategorySlide';
import ProductShow from '../Components/ProductShow';


function Home() {
  return (
    <div>    
         <Headbackground/>
        <div className='main-body'>
            <CarousalMaster/>     
           
            <Adslist/>
            <CategorySlide/>            
            <ProductShow/>   
          
            </div>
    </div>
  )
}

export default Home