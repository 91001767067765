

const filterReducer = (state, action) => {


  if (action.type === 'ADD_FILTER') {
    return {
      ...state,
      filterList: [
        ...state.filterList,
        { brand_id:action.brandId,brand_name: action.brandName, status: true }
      ],
      sortList: state.sortList ? [...state.sortList] : []
    };
  } else if (action.type === 'REMOVE_FILTER') {
            
    const updatedFilterList = state.filterList.filter( i => i.brand_name !== action.brandName);  
    const updatedSortList = state.sortList.filter( i => i.sort_name !== action.sortName);     
    return {
      ...state,
      filterList: updatedFilterList,
      sortList:updatedSortList
    };
  } else if (action.type === 'SORT_FILTER') {
    return {    
      filterList: [...state.filterList],
      sortList:[{ sort_id:action.sortId,sort_name: action.sortName}]
    };
  }
  else if (action.type === 'CLEAR_ALL') {
    return {    
      filterList: [],
      sortList:[]
    };
  }else {
    return state;
  }


};

export default filterReducer;