import React,{useState,useRef} from 'react'

import ProductCard from './ProductCard';

import CarouselMulti from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';


function MultiCarousalSlid({productData}) {
    const carouselRef = useRef(null);
    const [autoPlay, setAutoPlay] = useState(true);
  
    const handleTouchStart = () => {
       
      if (autoPlay) {
       // carouselRef.current.stopAutoplay();
        setAutoPlay(false);
      } else {
       // carouselRef.current.startAutoplay();
        setAutoPlay(true);
      }
    };
    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1200 },
            items: 5,
            slidesToSlide: 1, // Number of slides to scroll
            partialVisibilityGutter: 40, // Space between slides
        },
        tablet: {
            breakpoint: { max: 1200, min: 600 },
            items: 3,
            slidesToSlide: 1, // Number of slides to scroll
            partialVisibilityGutter: 10, // Space between slides
        },
        mobile: {
            breakpoint: { max: 600, min: 0 },
            items:2,
            slidesToSlide: 1, // Number of slides to scroll
            partialVisibilityGutter:100 // Space between slides
        },
    };

  return (
    <div>

<CarouselMulti
                            responsive={responsive}
                            additionalTransfrom={0}
                            arrows
                            ref={carouselRef}
                            autoPlay={autoPlay}
                            stopOnHover
                            autoPlaySpeed={3000}               
                            centerMode={false}
                            className=''
                            containerClass='container-with-dots'
                            dotListClass=''
                            draggable
                            focusOnSelect={false}
                            infinite
                            itemClass=''
                            keyBoardControl
                            minimumTouchDrag={80}
                            renderButtonGroupOutside={false}
                            renderDotsOutside={false}
                            sliderClass=''
                            slidesToSlide={1}
                            swipeable
                        >
                        {productData.map((product, index2) => (
                                     <div  key={index2}  onTouchEnd={handleTouchStart} onTouchStart={handleTouchStart} onClick={handleTouchStart}>
                                            <ProductCard  itemsingle={product} />
                                    </div>
                            ))}
                        </CarouselMulti>
    </div>
  )
}

export default MultiCarousalSlid