import React from 'react'



import homefill from '../images/404.png';
import { Link } from 'react-router-dom';

function Error() {
  return (
<div className='error-page'>


         <div className='error-div-img'>
                <img className="d-block" src={homefill} alt="home" />

        </div>
        <div>
            <h2><strong>Ohh .. !</strong> Something went wrong here...</h2>
            <p> Just keep browsing to get back on track</p>
            <Link to="/"> Back to home page</Link>
        </div>
    </div>
  )
}

export default Error