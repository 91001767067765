import React, { useState } from 'react'



function ItemNav() {



    const [activeTab, setActiveTab] = useState('description');

    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };


  return (
    <div className='gtt-tab-div'>
                <ul className="nav justify-content-center">
                <li className="nav-item">
                    <a
                        className={`nav-link ${activeTab === 'description' ? 'active' : ''}`}
                        href="#"
                        onClick={() => handleTabClick('description')}
                    >
                        Description
                    </a>
                </li>
                <li className="nav-item">
                    <a
                        className={`nav-link ${activeTab === 'specification' ? 'active' : ''}`}
                        href="#"
                        onClick={() => handleTabClick('specification')}
                    >
                        Specification
                    </a>
                </li>
                <li className="nav-item">
                    <a
                        className={`nav-link ${activeTab === 'review' ? 'active' : ''}`}
                        href="#"
                        onClick={() => handleTabClick('review')}
                    >
                        Review
                    </a>
                </li>
            </ul>

            <div id="description" className={`tab-content ${activeTab === 'description' ? 'active' : ''}`}>
            <pre>
                    BRAND : VOGATI
                    POWER : 1100W
                    MIXING BOWL CAPACITY : 7 L
                    VARIABLE SPEED CONTROL: 6
                    MATERIAL: STAINLESS STEEL, ABS PLASTICITEM WEIGHT: 5.8 KG
                    PLUG TYPE: 2PIN EURO PLUG
                    POWER CONSUMPTION: 220V-240V 50/60HZ
                    PACKAGE DIMENSIONS: H 36.5 CM, W 42.5 CM, L 28 CM. 
                    1100W FULL COPPER MOTOR 8825. FULL METAL GEAR SYSTEM. 7L STAINLESS STEEL MIXING BOWL. ROTARY SWITCH 6 SPEEDS WITH PULSE CONTROL. ABS PLASTIC HOUSING WITH INJECTION COLORS. LIFTING MECHANISM WITH SAFTY SWITCH FOR USING SAFETY AND EASILY. ANTI-SLIP FEET FOR MIXING MORE STABLY. ACCESSORIES 1 PC DOUGH HOOK 1 PC BEATER 1 PC EGG WISK 1 PC TRANSPARENT SPLASH LID.• SUITABLE FOR- CAKES, BREAD, COOKIES, PASTRY, MUFFINS, AND WAFFLES
                    STAINLESS STEEL BOWL: The bowl is narrow at the bottom, which helps the dough gather in one spot, reducing the likelihood of the hook failing to catch it and facilitating uniform consistency.EVERAL SPEED SETTINGS: There are three attachments, so one can select one that best suits the ingredients and adjust the speed accordingly. You will find the ideal speed for everything from folding to mixing and whisking with 6 speeds to choose from.Make homemade bread and cookies, or kneads large batches of heavy dough smoothly and efficiently using the VOGATI VE-192 kitchen machine. Even if you are not a kitchen pro, you expect a lot from your kitchen appliances. The vogati stand mixer is the best kitchen machine for beginners it is sturdy and compact, perfected for everyday family use and basic preparation. VE-192 a minimalistic look and effortlessly performs functions like mixing, kneading and beating with ease. The VE-192 is equipped with a planetary motion kneader. The kneader/dough hook rotates whilst moving around the inside of the bowl in such a way that they reach all areas of the bowl. This in turn ensures all ingredients are thoroughly mixed, which is the ultimate test of good performance for a kitchen machine.MADE IN CHINA
            </pre>
            </div>

            <div id="specification" className={`tab-content ${activeTab === 'specification' ? 'active' : ''}`}>
               <pre>
               STAINLESS STEEL BOWL: The bowl is narrow at the bottom, which helps the dough gather in one spot, reducing the likelihood of the hook failing to catch it and facilitating uniform consistency.EVERAL SPEED SETTINGS: There are three attachments, so one can select one that best suits the ingredients and adjust the speed accordingly. You will find the ideal speed for everything from folding to mixing and whisking with 6 speeds to choose from.Make homemade bread and cookies, or kneads large batches of heavy dough smoothly and efficiently using the VOGATI VE-192 kitchen machine. Even if you are not a kitchen pro, you expect a lot from your kitchen appliances. The vogati stand mixer is the best kitchen machine for beginners it is sturdy and compact, perfected for everyday family use and basic preparation. VE-192 a minimalistic look and effortlessly performs functions like mixing, kneading and beating with ease. The VE-192 is equipped with a planetary motion kneader. The kneader/dough hook rotates whilst moving around the inside of the bowl in such a way that they reach all areas of the bowl. This in turn ensures all ingredients are thoroughly mixed, which is the ultimate test of good performance for a kitchen machine.MADE IN CHINA
               </pre>
            </div>

            <div id="review" className={`tab-content ${activeTab === 'review' ? 'active' : ''}`}>
                Content of Review Tab
            </div>

    </div>

  )
}

export default ItemNav