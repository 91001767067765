import React,{useState,useRef} from 'react'
import ShowHeadingList from './ShowHeadingList'


import 'react-multi-carousel/lib/styles.css';


import 'animate.css';

import { headingData,productData } from '../Data';

import MultiCarousalSlid from './MultiCarousalSlid';

function ProductShow() {


   
 

  const headings =""
  return (
    <div>
         {headingData.map((heading, index) => (
            <div key={index}>
            <ShowHeadingList   heading={heading.heading_name}/>                           
               <div className='product-carousel-xl'>

                <MultiCarousalSlid productData={productData}/>
            </div>
            <div className='img-banner'>

                {heading.img_url.map((image, ind) => (
                <img className="img-fluid w-100 p-1" key={ind} src={image} alt="" />
                ))}
                </div>
                
           </div>
             
        ))}
        

        
    </div>
  )
}

export default ProductShow