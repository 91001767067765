import React,{useRef, useState } from 'react'
import ImageMagnifier from './ImageMagnifier';

import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

function ItemImgSlideScroll({imglist}) {


  const containerRef = useRef(null);
  const [showLeftButton, setShowLeftButton] = useState(false);
  const [showRightButton, setShowRightButton] = useState(true);
  const [activeImageIndex, setActiveImageIndex] = useState(0);
  const [largeImage, setLargeImage] = useState(imglist[0]?.img_url || null);


    const [photoIndex, setPhotoIndex] = useState(0);
    const [isOpen, setisOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    const handleImageLoad = () => {
      setIsLoading(false); // Set isLoading to false when the image has loaded
    };

  const scrollLeft = () => {
    if (containerRef.current) {
      containerRef.current.scrollTo({
        left: containerRef.current.scrollLeft - 200,
        behavior: 'smooth' // Add smooth scrolling behavior
      });
    }
  };

  const scrollRight = () => {
    if (containerRef.current) {
      containerRef.current.scrollTo({
        left: containerRef.current.scrollLeft + 200,
        behavior: 'smooth' // Add smooth scrolling behavior
      });
    }
  };

  const handleScroll = () => {
    if (containerRef.current) {
      const scrollPosition = containerRef.current.scrollLeft;
      setShowLeftButton(scrollPosition > 0);
      setShowRightButton(
        scrollPosition < containerRef.current.scrollWidth - containerRef.current.clientWidth
      );
    }
  };

  const handleImageClick = (index,img_url) => {
    setActiveImageIndex(index);
    setLargeImage(img_url);
    
  };

  const handleImageClickMob = (index,img_url) => {
  
    setisOpen(true);
    setPhotoIndex(index)
    setIsLoading(true);
  };

  return (
     <div>
     <div className='item-mastr-img-div'>
     {largeImage && (
        <ImageMagnifier smallImage={largeImage} largeImage={largeImage} />
      )}
    <div className='itm-img-list'>
    <a className={`gtt-scroll-button left `}  onClick={scrollLeft} ><i className="fa fa-angle-left fa-2x"></i></a>
    <div className="itm-img-list-container"  ref={containerRef} onScroll={handleScroll}>

          
            <div className="itm-img-list-content">
            {imglist.map((item,index) => (
            <a key={item.id} href='#' onClick={() => handleImageClick(index, item.img_url)}><img
            src={item.img_url}
            alt="Small Image"
            className={activeImageIndex === index ? 'active-image' : ''}
          /></a>     
                ))}
            </div>    
        
    </div>
     <a className={`gtt-scroll-button right`} onClick={scrollRight}><i className="fa fa-angle-right fa-2x"></i></a>
 </div>
 </div>
  <div className='item-mastr-img-div-mob'>
        <div className="itm-img-list-content-mob">
            {imglist.map((item,index) => (
            <a key={item.id} href='#' onClick={() => handleImageClickMob(index, item.img_url)}><img
            src={item.img_url}
            alt={`Image ${index}`}            
            className={activeImageIndex === index ? 'active-image' : ''}
          /></a>               
                ))}


      {isOpen && (
                <Lightbox               
                  mainSrc={imglist[photoIndex].img_url}
                  nextSrc={imglist[(photoIndex + 1) % imglist.length].img_url}
                  prevSrc={imglist[(photoIndex + imglist.length - 1) % imglist.length].img_url}
                  onCloseRequest={() =>  {setisOpen(false)  ;  setIsLoading(false);}}
                  onMovePrevRequest={() =>
                   
                    setPhotoIndex((photoIndex + imglist.length - 1) % imglist.length)
                  }
                  onMoveNextRequest={() =>
                    setPhotoIndex((photoIndex + 1) % imglist.length)
                  
                  }
                  onImageLoad={handleImageLoad}
                />
              )}
              {isLoading && <div className="preloader">Loading...</div>}
            </div>    
  </div>
 </div>
  )
}

export default ItemImgSlideScroll