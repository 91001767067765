import React from 'react'

function Subscribe() {
  return (
    <div>
        <div className='subscribe-div'>

                
                <div className='subscribe-1 '>
                <div className='subscribe-text'>

<b>Subscribe </b> <br></br>to our news letter
</div>
                   <i className="mb-2 fa fa-envelopes-bulk fa-6x "></i>
                  
                        <div className='input-subscribe'>
                                                                                
                                        <input type="text" className="form-control form-control-sm"  placeholder='Enter email address... '/>                                                                                                                              
                            <button className="subscribebtn btn btn-sm " type="button"><i className="fa fa-hand-pointer"></i> &nbsp;Subscribe </button>
                           
                        </div>
                </div>
               

        </div>
    </div>
  )
}

export default Subscribe