import React, { useState, useEffect } from 'react'
import ProductCard from './ProductCard';
import { brandImages, optionsSelect, pageShowNo } from '../Data';
import { useFilterContext } from '../ContextProvider/FilterContext';
import { useParams } from 'react-router-dom';
import Filter from './Filter';



function ItemShow() {



  const contextValue = useFilterContext();


  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(20);

  const [indexOfLastItem, setIndexOfLastItem] = useState(0);
  const [indexOfFirstItem, setIndexOfFirstItem] = useState(0);
  const [currentItems, setCurrentItems] = useState([]);
  const [totalPages, setTotalPages] = useState(0);


  useEffect(() => {
    // Calculate values based on the current state
    const newIndexOfLastItem = currentPage * itemsPerPage;
    const newIndexOfFirstItem = newIndexOfLastItem - itemsPerPage;
    const newCurrentItems = brandImages.slice(newIndexOfFirstItem, newIndexOfLastItem);
    const newTotalPages = Math.ceil(brandImages.length / itemsPerPage);
    // Update state values with the calculated values
    setIndexOfLastItem(newIndexOfLastItem);
    setIndexOfFirstItem(newIndexOfFirstItem);
    setCurrentItems(newCurrentItems);
    setTotalPages(newTotalPages);
  }, [currentPage, itemsPerPage]);


  const handlePageChange = newPage => {
    setCurrentPage(newPage);
  };

  const handleFirstPage = () => {
    setCurrentPage(1);
  };

  const handleLastPage = () => {
    setCurrentPage(totalPages);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
    const { filter, dispatch } = useFilterContext();

  /*********SELECT CUSTOMER OPTIONS ******/
  const [selectedOption, setSelectedOption] = useState(optionsSelect[0].value);
  const [selectedPageNo, setSelectedPageNo] = useState(pageShowNo[0].value);

  const handleSelectChange =   event =>{

    console.log("=============================="+ event.target.sel)

    const selectedOption = event.target.options[event.target.selectedIndex];
    const value = selectedOption.value;
    const text = selectedOption.text;

    setSelectedOption(value);
    let stats='SORT_FILTER';
    dispatch({ type: stats,sortId:value,sortName: text });
  };

  const handleSelectPageNoChange = event => {
    const newSelectedPageNo = event.target.value;
    setSelectedPageNo(newSelectedPageNo);
    setItemsPerPage(newSelectedPageNo);
    setCurrentPage(1);
  };
  /*********SELECT CUSTOMER OPTIONS END ******/

  return (
    <div className='gtt-itemshow-div'>


      <div className='row w-100 gx-0 justify-content-between'>
        <div className='col-lg-3 col-md-6 col-sm-6 col-4'>

          <select className='form-select' value={selectedOption} onChange={handleSelectChange}>
            {optionsSelect.map(option => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>

        </div>
        <div className='filter-col col-lg-7 col-md-6 col-sm-6 col-6 text-start align-text-bottom'>
           <Filter contextValue={contextValue} />   
        </div>
        <div className='col-lg-2 col-md-4 col-sm-4 col-4 '>

          <select className='form-select' value={selectedPageNo} onChange={handleSelectPageNoChange}>
            {pageShowNo.map(option => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </select>

        </div>
      </div>


      <div className='row justify-content-center m-0'>


        {currentItems.map((item, index) => (
        
           <div key={index} className="col-lg-3 col-md-4 col-sm-6 col-6 p-1">
             <ProductCard  itemsingle={item}/>
             
           
           </div>
        ))}
      </div>


      <nav aria-label="Page navigation " className='pagination-nav'>
        <ul id="" className="pagination justify-content-center">
          <li>
            <a className="" onClick={handlePrevPage}>
              <i className='fa fa-angle-double-left' />
            </a>
          </li>
          {Array.from({ length: totalPages }).map((_, index) => {
            const pageNumber = index + 1;

            if (
              pageNumber === 1 || // Always show the first page
              pageNumber === totalPages || // Always show the last page
              Math.abs(pageNumber - currentPage) <= 1 || // Show pages near the active page
              (pageNumber === currentPage - 2 || pageNumber === currentPage + 2) // Show pages with a gap of 2 from active page
            ) {
              return (
                <li key={index} className={` ${pageNumber === currentPage ? 'active' : ''}`}>
                  <a className="" onClick={() => handlePageChange(pageNumber)}>
                    {pageNumber}
                  </a>
                </li>
              );
            } else if (
              pageNumber === currentPage - 3 || // Show ellipsis 3 pages before active page
              pageNumber === currentPage + 3 // Show ellipsis 3 pages after active page
            ) {
              return (
                <li key={index} className=" disabled">
                  <span className="">...</span>
                </li>
              );
            }
            return null; // Return null for pages not meeting the above conditions
          })}
          <li>
            <a className="" onClick={handleNextPage}>
              <i className='fa fa-angle-double-right' />
            </a>
          </li>
        </ul>
      </nav>
    </div>
  )
}

export default ItemShow
