import React from 'react'
import { companySlid } from '../Data';

import { Carousel } from 'react-bootstrap';
import 'animate.css';
function Company() {
  return (
    <div>

            <Carousel>
                    {
                        companySlid.map((image, index) => {

                            return <Carousel.Item key={index} >

                                <div className="gtt-carousel d-flex justify-content-around">
                                    <div className=" ">
                                        <img
                                            className="d-block w-100  "
                                            src={image.img_url}
                                            alt={image.link_name}
                                        />
                                    </div>                                    
                                </div>

                            </Carousel.Item>
                        })
                    }
                </Carousel>

              <div className='compnay-section'>
                    <h4 className='text-primary'>Golden Tools Trading LLC</h4>
                    <p>
                    Since its inception in 1982, Golden Tools Trading LLC has grown from strength to strength as one of the leading providers of an extensive range of power and hand tools, hardware, accessories, consumables, machineries, and other functional equipments across the Middle East.
                    Recognized as an industry leader with a reputation for innovation, quality and integrity, Golden Tools Trading has continuously met the ever-changing requirements of diverse customers through exemplary service and constant expansion of our product lines.
                    </p>
                    <p>
                    Over the years, we are fully committed to providing end-to-end solutions under one roof, while offering competitive pricing and comprehensive support to our valued customers.
                    The rapid expansion of Golden Tools Trading stems from creating a culture that fosters long-term relationships among all of our strategic trade partners across the USA, United Kingdom, Italy, Germany, Japan, China, Hong Kong, Taiwan, South Korea and Vietnam. We have fully dedicated ourselves to import and export high quality, cost-effective and world className products.
                    </p>
                    <p>
                    The vision of Golden Tools Trading is to become the ultimate “one-stop shop” in providing world className brands and own label products through continuous growth and product line expansion in accordance with the needs of diverse customers.</p>
                    <p>
                    At Golden Tools Trading, our mission is to continuously provide a broad range of premium quality products, at the most competitive price, with a goal to expand its distribution network and strengthen its position in the global market.
                    </p>

                    <div className='row'>
                        <div className='col-md-4 col-sm-12' >
                        <h6 className='text-primary'>HIGHEST QUALITY SERVICE</h6>
                                <div className='card-company'>
                                 <div className='p-3 text-primary'>
                                   <i className="fa fa-user-gear fa-4x"></i>
                                 </div>  
                                  <div>
                                   
                                    <p>
                                    The ultimate goal is to remain competitive and consistently provide end-to-end solutions that exceed customers’ expectations. This is achieved by recruiting and equipping the best people in the industry through teamwork, confidence in our work and first-className quality service.
                                    </p>
                                    </div> 
                                </div>
                        </div>
                        <div className='col-md-4 col-sm-12' >
                        <h6 className='text-primary'>WIDE DISTRIBUTION CHANNELS</h6>
                        <div className='card-company'>

                                    <div className='p-3 text-primary'>
                                  
                                   <i className="fa fa-paper-plane fa-4x"></i>
                                 </div>
                                 <div>
                                    
                                  <p>
                                  Golden Tools Trading continues to increase its distributional efficiency by establishing successful offices, branches and showrooms across the UAE, as well as expanding our business via online web store.
                                    </p>
                                </div>
                                </div>
                        </div>
                        <div className='col-md-4 col-sm-12' >
                        <h6 className='text-primary'>PROFESSIONALISM AND INTEGRITY</h6>
                        <div className='card-company'>

                        <div className='p-3 text-primary'>
                                   
                                   <i className="fa fa-box-open fa-4x"></i>
                                 </div>
                                 <div>
                               
                                  <p>
                                  To provide exemplary service that can add value to our clients is our top priority. Willingness to go extra mile and openness for improvement are keys in achieving the highest quality of work.
                                </p>
                                </div>
                                </div>
                        </div>

                    </div>


                    <h4 className='text-primary p-3'>Branches</h4>


                    <div className='row'>
                        <div className='col-md-4 col-sm-12' >

                            <div className='card-location'>
                            <div className='centered-content'>
                                                <span>Head Office</span>
                                                <hr className='gtt-hr'></hr>
                                            </div>
                                                                            
                                         <div className='card-location-dtls-item'>
                                         <i className="fa fa-mobile-screen-button "></i>
                                            <addres>
                                                Tel: + 971 4 2238240,2722128 
                                                <br></br>Fax: + 971 4 2722129
                                            </addres>
                                            
                                            </div>
                                            <div className='card-location-dtls-item'>
                                            <i className="fa-solid fa-location-dot "></i>
                                            <addres>
                                            Dubai Investment Park-1- Dubai,UAE                                         
                                            </addres>
                                            </div>
                                            <div className='card-location-dtls-item'>
                                            <i className="fa-regular fa-envelope "></i>
                                            <addres>
                                            gttmn@emirates.net.ae                                         
                                            </addres>
                                         </div>

                                  
                            </div>
                        </div>

                            <div className='col-md-4 col-sm-12' >

                                <div className='card-location'>
                                    <div className='centered-content'>
                                                    <span>Dubai (DIP)</span><label>-Showroom</label>
                                                    <hr className='gtt-hr'></hr>
                                                </div>
                                                <div className='card-location-dtls-item'>
                                                 <i className="fa fa-user "></i>
                                                <addres>
                                                Mr. Mehran Hossainia
                                                </addres>
                                                
                                                </div>              
                                            <div className='card-location-dtls-item'>
                                            <i className="fa fa-mobile-screen-button "></i>
                                                <addres>
                                                    Tel: + 971 4 2238240,2722128 
                                                    <br></br>Fax: + 971 4 2722129
                                                </addres>
                                                
                                                </div>
                                                <div className='card-location-dtls-item'>
                                                <i className="fa-solid fa-location-dot "></i>
                                                <addres>
                                                Dubai Investment Park-1- Dubai,UAE                                
                                                </addres>
                                                </div>
                                                <div className='card-location-dtls-item'>
                                                <i className="fa-regular fa-envelope "></i>
                                                <addres>
                                                sales.hs@goldentoolsuae.ae                           
                                                </addres>
                                            </div>

                                    
                                    </div>
                                </div>




                                <div className='col-md-4 col-sm-12' >

<div className='card-location'>
    <div className='centered-content'>
                    <span>Dubai (Deira)</span><label>-Showroom</label>
                    <hr className='gtt-hr'></hr>
                </div>
                <div className='card-location-dtls-item'>
                 <i className="fa fa-user "></i>
                <addres>
                Mr. Sherif
                </addres>
                
                </div>              
            <div className='card-location-dtls-item'>
            <i className="fa fa-mobile-screen-button "></i>
                <addres>
                 Tel: + 971 4 2238240
                    <br></br>Fax: + 971 4 2279758
                </addres>
                
                </div>
                <div className='card-location-dtls-item'>
                <i className="fa-solid fa-location-dot "></i>
                <addres>
                Benyas Square (Nasser square) Opp.Mount Royal Hotel - Deira                     
                </addres>
                </div>
                <div className='card-location-dtls-item'>
                <i className="fa-regular fa-envelope "></i>
                <addres>
                gttmain@goldentools.ae                  
                </addres>
            </div>

    
    </div>
</div>


<div className='col-md-4 col-sm-12' >

<div className='card-location'>
    <div className='centered-content'>
                    <span>Dubai (Al Quoz)</span><label>-Showroom</label>
                    <hr className='gtt-hr'></hr>
                </div>
                <div className='card-location-dtls-item'>
                 <i className="fa fa-user "></i>
                <addres>
                Mr. Aziz Malekzadeh
                </addres>
                
                </div>              
            <div className='card-location-dtls-item'>
            <i className="fa fa-mobile-screen-button "></i>
                <addres>
                Tel: + 971 4 3393311

                    <br></br>Fax: + 971 4 3393331
                </addres>
                
                </div>
                <div className='card-location-dtls-item'>
                <i className="fa-solid fa-location-dot "></i>
                <addres>
                SheikhZayedRoad next to the BMW Showroom Al Quoz              
                </addres>
                </div>
                <div className='card-location-dtls-item'>
                <i className="fa-regular fa-envelope "></i>
                <addres>
                gttbr@emirates.net.ae       
                </addres>
            </div>

    
    </div>
</div>


<div className='col-md-4 col-sm-12' >

<div className='card-location'>
    <div className='centered-content'>
                    <span>Sharjah</span><label>-Showroom</label>
                    <hr className='gtt-hr'></hr>
                </div>
                <div className='card-location-dtls-item'>
                 <i className="fa fa-user "></i>
                <addres>
                Mr. Omar
                </addres>                
                </div>              
            <div className='card-location-dtls-item'>
            <i className="fa fa-mobile-screen-button "></i>
                <addres>
                Tel: + 971 6 5330055
                    <br></br>Fax: + 971 6 5330077
                </addres>
                
                </div>
                <div className='card-location-dtls-item'>
                <i className="fa-solid fa-location-dot "></i>
                <addres>
                Industrial Area #.1, between City center & BMW road,Sedan signal         
                </addres>
                </div>
                <div className='card-location-dtls-item'>
                <i className="fa-regular fa-envelope "></i>
                <addres>
                gttbrsh3@emirates.net.ae
                </addres>
            </div>

    
    </div>
</div>




<div className='col-md-4 col-sm-12' >

<div className='card-location'>
    <div className='centered-content'>
                    <span>Abu Dhabi</span><label>-Showroom</label>
                    <hr className='gtt-hr'></hr>
                </div>
                <div className='card-location-dtls-item'>
                 <i className="fa fa-user "></i>
                <addres>
                Mr. Aseef
                </addres>                
                </div>              
            <div className='card-location-dtls-item'>
            <i className="fa fa-mobile-screen-button "></i>
                <addres>
                Tel: + 971 2 5550425

                    <br></br>Fax: + 971 2 5550426
                </addres>
                
                </div>
                <div className='card-location-dtls-item'>
                <i className="fa-solid fa-location-dot "></i>
                <addres>
                        
                </addres>
                </div>
                <div className='card-location-dtls-item'>
                <i className="fa-regular fa-envelope "></i>
                <addres>
                gttadbr@emirates.net.ae
                </addres>
            </div>

    
    </div>
</div>

<div className='col-md-4 col-sm-12' >

<div className='card-location'>
    <div className='centered-content'>
                    <span>Al Ain</span><label>-Showroom</label>
                    <hr className='gtt-hr'></hr>
                </div>
                <div className='card-location-dtls-item'>
                 <i className="fa fa-user "></i>
                <addres>
                Mr. Khaleel
                </addres>                
                </div>              
            <div className='card-location-dtls-item'>
            <i className="fa fa-mobile-screen-button "></i>
                <addres>
                Tel: + 971 3 7228782
                    <br></br>Fax: + 971 3 7228785
                </addres>
                
                </div>
                
                <div className='card-location-dtls-item'>
                <i className="fa-regular fa-envelope "></i>
                <addres>
                gttainbr@emirates.net.ae
                </addres>
            </div>

    
    </div>
</div>

<div className='col-md-4 col-sm-12' >

<div className='card-location'>
    <div className='centered-content'>
                    <span>Qatar</span><label>-Golden Tools Trading WLL</label>
                    <hr className='gtt-hr'></hr>
                </div>
                <div className='card-location-dtls-item'>
                 <i className="fa fa-user "></i>
                <addres>
                Mr.Gafoor
                </addres>                
                </div>              
            <div className='card-location-dtls-item'>
            <i className="fa fa-mobile-screen-button "></i>
                <addres>
                Tel: + 974 44474005
                    <br></br>Fax: + 974 3 44474006
                </addres>
                
                </div>
                
                <div className='card-location-dtls-item'>
                <i className="fa-regular fa-envelope "></i>
                <addres>
                gttbrqatar@hotmail.com
                </addres>
            </div>

    
    </div>
</div>



<div className='col-md-4 col-sm-12' >

<div className='card-location'>
    <div className='centered-content'>
                    <span>K.S.A</span><label>-Nami Golden Tools Trading CO(LLC)</label>
                    <hr className='gtt-hr'></hr>
                </div>
                <div className='card-location-dtls-item'>
                 <i className="fa fa-user "></i>
                <addres>
                Mr.Saneesh
                </addres>                
                </div>              
            <div className='card-location-dtls-item'>
            <i className="fa fa-mobile-screen-button "></i>
                <addres>
                Tel: + 966 112414204
                    <br></br>Fax: + 966 112414204
                </addres>
                
                </div>
                
                <div className='card-location-dtls-item'>
                <i className="fa-regular fa-envelope "></i>
                <addres>
                infongtt@goldentools.ae
                </addres>
            </div>

    
    </div>
</div>



                            </div>

                            


                        
             </div>

    </div>
  )
}

export default Company