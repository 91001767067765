
import React, { useEffect, useState,useRef } from 'react'

import 'animate.css';
import { NavLink } from 'react-router-dom';

function OffCanvasMob({ isOpen, toggleMenu ,offCanvasRef}) {




 


  return (
    <div ref={offCanvasRef} className={`off-canvas-container animate__animated ${isOpen ? 'open  animate__fadeInRight' : ''}`}>
      <div className='mob-profile'>
           <p>Hello</p>
           <a href='#'>User Name</a>
         
           
      </div>  
      <a href="#" className="toggle-button" onClick={toggleMenu}>
      <i className="fa fa-rectangle-xmark  fa-2x"></i>     
      </a>

      <div className="off-canvas-menu">
        <ul >
          <li onClick={toggleMenu}><NavLink to="/" >Home</NavLink></li>
          <li onClick={toggleMenu}><a href='#'>Best Offers</a></li>
          <li onClick={toggleMenu}><NavLink to="brands" >Brand</NavLink></li>
          <li onClick={toggleMenu}><NavLink to="wholesale" >Whole Sale</NavLink> </li>
          <li onClick={toggleMenu}><NavLink to="company" >About us</NavLink>  </li>
          <li onClick={toggleMenu}><NavLink to="profile/support" >Help & Support</NavLink> </li>
          <li onClick={toggleMenu}><NavLink to="profile" >Profile</NavLink> </li>
          <li onClick={toggleMenu}><NavLink to="profile" >Settings</NavLink> </li> 
          <li onClick={toggleMenu} ><NavLink to="/profile/myorder" >My Orders</NavLink></li>
        </ul>
      </div>
      <div className="main-content">
        {/* Your main content goes here */}
      </div>
    </div>
  )
}

export default OffCanvasMob