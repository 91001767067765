import React from 'react'
import visaimg from '../images/payment/visa.svg';
import masterimg from '../images/payment/master.svg';
import { useNavigate } from 'react-router-dom';
function Confirm({ updateStep }) {
    const navigate = useNavigate();
    const handleSomeAction = () => {
        // Update the current step by calling the updateStep function
        updateStep(2); // This will set the step to 1 (or any other step you want)
    };

    return (
        <div className='confirm mb-3'>
            <div className="checkout-head p-2" >
                <h6>Shipping Address</h6>
            </div>
            <div className='confirm-main'>

                <div className='confirm-address'>
                    <h6>NISHAD MOIDENKUTTY</h6>
                    <p>Golden Tols Trading LLC</p>
                    <p>Dubai Investment Park-1</p>
                    <p>Dubai</p>
                    <p >Mob:056145451</p>

                </div>
                <div className='confirm-edit'>
                    <a href='#' onClick={handleSomeAction} > Change</a>
                </div>
            </div>

            <div className="checkout-head p-2" >
                <h6>Payment</h6>
            </div>
            <div className='confirm-payment-main'>

                <div className='confirm-payment'>
                    <div className="form-check ">
                        <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                        <label className="form-check-label" for="flexRadioDefault1">
                           <b> Debit/Credit Card</b>
                        </label>
                        <address>
                            NISHAD MOIDENKUTTY <br></br>
                            Master Card Ending 2243
                        </address>
                    </div>
                    <div className='cardimg'>
                        <div className='confirm-edit'>
                            <a href='#' onClick={handleSomeAction} > Add New Card</a><br></br>
                            <a href='#' onClick={handleSomeAction} > Edit Card</a>
                        </div>
                        <img
                            src={visaimg}
                            alt='Visa'
                        />
                        <img
                            src={masterimg}
                            alt='Master'
                        />
                    </div>


                </div>
                <hr className='item-hr'></hr>
                <div className='confirm-payment'>
                <div className="form-check ">
                        <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" />
                        <label className="form-check-label" for="flexRadioDefault2">
                        <b>Cash on delivery</b>
                        </label>
                         
                    </div>
                    </div>
            </div>

          
                <div className="col-md-12 p-3">

                <div className="d-grid gap-2 d-md-flex justify-content-md-end ">
                    <button onClick={() => navigate('/ordercomplete')}  className="btn btn-sm btn-primary me-md-2" type="button">PLACE ORDER</button>
                </div>

                </div>
                

        </div>
    )
}

export default Confirm