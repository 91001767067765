import React from 'react'
import { orderhistory} from '../Data';
import OrderCard from '../Components/OrderCard';
function TrackOrderDtls() {

    const order= [
        { order_id: 1
          , tran_no: 'GTTORD000029'
          , bill_no: '203201'
          , tran_date: '23-05-2023'
          , expected_delivery: '28-05-2023'
          , total_amount: '350.00'
          , total_itm: '10'
          ,address: "Nishad M M\nmanakkathodi\nduba "    
          ,stats:"Processing"
        }
    ]
    

  return (
    <div>
        <div className='row mb-5 mt-2'>
    <div className='col-md-12 col-sm-12'>
    <div>
                <OrderCard order={order[0]} arrowshow={true} />
            </div>
            <div className='cart-head'>
                Order Track Details
              
            </div>

            
            <div>
            <table className="table table-striped">
                 <thead>
                    <tr>
                        <th scope="col">#</th>
                        <th scope="col">Date</th>
                        <th scope="col">Description</th>                   
                    </tr>
                </thead>
                    <tbody>
                    {orderhistory.map((item, index) => (
                            
                            <tr key={index} >
                                <th scope="row">{index+1}</th>
                                <td>{item.tran_date}</td>
                                <td className='text-start'>{item.description}</td>                              
                            </tr>
                        
                    ))}
                    </tbody>
                 </table>
            </div>


    </div>
  
</div>
</div>
  )
}

export default TrackOrderDtls