import React, { useState } from 'react'
import CartProductCard from '../Components/CartProductCard'
import BankDtls from './BankDtls';
import Agreement from '../Components/Agreement';

function ReturnForm() {
        const [bankFlg, setBankFlg] = useState(false);

        const [showModal, setShowModal] = useState(false);

        const openModal = () => {
            setShowModal(true);
        };

        const closeModal = () => {
            setShowModal(false);
        };

    const item= {
        id: 1
        , itm_title: 'Mixer Grinder'
        , itm_name: 'STAND MIXER 1100W KITCHEN MACHINE | ELECTRIC MIXER | 7L STAINLESS STEEL MIXING BOWL WITH LID | 6 SPEEDS | SAFTY SWITCH | DOUGH HOOK- BEATER- WIRE WHIP VE-192'
        , brand_name: 'Vogatronix'
        , category_name: 'Home Appliances'
        , img_url: require('../images/product/6291116278177_1.jpg')
        , freedelivery_flg: 'Y'
        , expected_delivery: '12-05-2023'
        , itm_qty: 1
        , sell_rate: '273.00'
        , base_rate: '372.00'
      }

        const showBankDtls=(e)=>{
                    const selectedValue = e.target.value;
                    if (selectedValue === "RTN") {
                        setBankFlg(true)
                    }else{
                        setBankFlg(false)
                    }    
                    console.log("==========================>>"+bankFlg)            
            }

  return (
    <div>

<CartProductCard   item={item} favorateflg={true} orderitem={true} trashflg={true}  />
<div className='cart-head'>
                                Return Item
                                  
                                </div>
            <div className='card return-card mb-4'>
                        <form className="row g-3 needs-validation" >
                                <div className="col-md-3">
                                    <label  className="form-label">Return Qty</label>
                                    <input type="text" className="form-control form-control-sm" id="validationCustom01" value={item.itm_qty}  readOnly required />
                                
                                </div>
                                <div className="col-md-9">
                                    <label className="form-label">Return Type</label>
                                        <select className="form-select form-select-sm" id="returnType" required  onChange={showBankDtls}>
                                        <option value="">Choose...</option>
                                        <option  value="RPC">Replacement</option>
                                        <option  value="RTN">Cash back return</option>
                                        </select>
                                
                                </div>

                                <div className="col-md-12">
                                    <label  className="form-label">Return Description</label>
                                    <textarea className="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
                                
                                </div>
                                <div className="col-md-6">
                                    <label  className="form-label">Contact Number</label>
                                    <input type="text" className="form-control form-control-sm" id="validationCustom01" value=""  />
                                
                                </div>

                                {
                                    bankFlg?<BankDtls returnFlg={true}/>:null
                                }

                        <div className="d-grid gap-2 d-md-flex justify-content-between">

                        
                                <div className="form-check">
                                <input className="form-check-input" type="checkbox" value="" id="invalidCheck" required/>
                                <label className="form-check-label" for="invalidCheck">
                                    I Agree to <a href='#' onClick={openModal}> Terms & conditions</a>
                                </label>
                               
                                </div>
                                                    <button className="btn btn-sm btn-primary me-md-2" type="button">SAVE & CONTINUE</button>
                                                </div>
                        </form>

                        <Agreement showModal={showModal} closeModal={closeModal} />
            </div>
    </div>
  )
}

export default ReturnForm