import React from 'react'
import image from '../images/bulk/bulkimg.jpg';
function Wholesale() {
  return (
    <div className='bulk'>  
        
        <div className='row'> 
        <div className='col-md-6'>

        {image ? (
                                    <img
                                        className="d-block w-100  "
                                        src={image}
                                        alt="" />
                                ) : null}
        </div>

        <div  className='col-md-6 bulk-desc'>
         <h5 className='text-warning'>SAVE BIG ON BULK & CORPORATE ORDERS</h5>
         <p>If you are a business or a buyer looking to make a bulk purchase, you have come to the right place. We offer a range of products, exclusive prices, personalized service, and flexible delivery options. Our products are perfect for a variety of purposes such as Outdoor Lifestyle, Gardening, Homeware, Paint & Décor, DIY & Tools, Automotive, Pet Products & more.
         </p>
         <p className='text-danger'>To know more, get in touch with us at<b> helpdesk@goldentoolsuae.ae</b></p>
        </div>
     </div>
    </div>
  )
}

export default Wholesale