import React from 'react'




function ShowHeadingList(props) {

    const { heading } = props;

  return (
     <div className='productlist-head-tag'>
        <div className='symbol-tag'>
                 {heading}
        </div>
        <div className='symbol-seemore'>
               <a href='#'>See More</a> 
        </div>                      
    </div>
  )
}

export default ShowHeadingList