import React from 'react'
import Applycouponcode from './Applycouponcode';
import { useNavigate } from 'react-router-dom';
function OrderSummary(props) {

    const navigate = useNavigate();

  
  return (
    <div> <div className='cart-head'>
    Order Summary
</div>

<div className='order-summary' style={{ height: '90%' }}>

      <div >
         {props.coupon==1?<Applycouponcode/>:null} 
         </div>

        <div className='order-summary-item'> 
           <div className='order-summary-item-name'> No Items </div> 
             <div className='order-summary-item-res'> 04 </div>
        </div>
        <div className='order-summary-item'> 
            <div className='order-summary-item-name'> Sub Total Items </div> 
             <div className='order-summary-item-res'> 2040.00 </div>
        </div>

        <div className='order-summary-item pt-3'> 
            <div className='order-summary-item-name'> You saved </div> 
             <div className='order-summary-item-res'> 200.00 </div>
        </div>
        <div className='order-summary-item'> 
            <div className='order-summary-item-name'> Delivery Charge </div> 
             <div className='order-summary-item-res'> 10.00 </div>
        </div>


       

        <hr className='item-hr'></hr>

        <div className='order-summary-item mb-3'> 
            <div className='order-summary-item-total'> Total  </div> 
             <div className='order-summary-item-total-res'> 2030.00 </div>
        </div>
         
        {props.coupon==1?<a  className="btn btn-sm  btn-lg btn-block buynow w-100 mb-3" onClick={() => navigate('/account')}>CHECK OUT</a>:null} 
        
        
        <a  className="btn btn-sm  btn-lg btn-block addcart w-100" onClick={()=>{navigate(-1);}} >CONTINUE SHOPPING</a>
</div></div>
  )
}

export default OrderSummary