import React from 'react'
import BrandSlide from './BrandSlide'




function BrandBottom() {
  return (    
     <div className='gtt-bottombrand'>
                
                <BrandSlide/>

    </div>
  )
}

export default BrandBottom