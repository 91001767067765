import React from 'react'

import { Carousel } from 'react-bootstrap';
import CarouselMulti from 'react-multi-carousel';
import 'animate.css';
import CarousalTwo from './CarousalTwo';

import img1 from '../images/img1.png';
import img2 from '../images/img2.png';
import img3 from '../images/img3.png';
import img4 from '../images/img4.png';


function CarousalMaster() {

    const images = [img1, img2, img3, img4];
 
    const masterCoursal=() => {

        return (
            <div className='gtt-master-carousel'>
                <Carousel>
                    {
                        images.map((image, index) => {

                            return <Carousel.Item key={index} >

                                <div className="gtt-carousel d-flex justify-content-around">
                                    <div className="half-picture animate__animated animate__fadeInRight animate__delay-1s">
                                        <img
                                            className="d-block w-100  "
                                            src={image}
                                            alt={`Slide ${index + 1}`}
                                        />
                                    </div>
                                    <div className="vl"></div>


                                    <div className="gtt-carousel-head animate__animated  animate__fadeInUp  animate__delay-1s ">

                                        <h3>VOGATRONIX</h3>
                                        <h2><b>Stand Mixer</b></h2>
                                        <h3> Full metal gear system</h3>
                                        <h3> steel mixing bowl</h3>
                                    </div>
                                </div>

                            </Carousel.Item>
                        })
                    }
                </Carousel>
            </div>

        )

    }

   

    return (
        <div className="slidsection" style={{width:'100%'}}>
            {
                masterCoursal()             
            }
           <CarousalTwo/>
        </div>
    )
}

export default CarousalMaster