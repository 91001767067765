import React from 'react'
import { cartItems,productData} from '../Data';
import CartProductCard from '../Components/CartProductCard';
import OrderCard from '../Components/OrderCard';


function MyOrderDtls(props) {
 
   
    const order= [
        { order_id: 1
          , tran_no: 'GTTORD000029'
          , bill_no: '203201'
          , tran_date: '23-05-2023'
          , expected_delivery: '28-05-2023'
          , total_amount: '350.00'
          , total_itm: '10'
          ,address: "Nishad M M\nmanakkathodi\nduba "    
          ,stats:"Processing"
        }
    ]
    
  return (
    <div>

    <div className='row mb-5 mt-2'>
                        <div className='col-md-12 col-sm-12'>
                        <div>
                                    <OrderCard order={order[0]} arrowshow={true} />
                                </div>
                                <div className='cart-head'>
                                    Order Details
                                  
                                </div>

                                
                                <div>
                                     {cartItems.map((item, index) => (
                                            <CartProductCard  key={index} item={item} favorateflg={true} orderitem={true} trashflg={false} returnflg={true}  />
                                     ))}
                                </div>
    
    
                        </div>
                      
                </div>
        </div>
  )
}

export default MyOrderDtls