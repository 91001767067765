import React, { useState }  from 'react'
import { useFilterContext } from '../ContextProvider/FilterContext';


function Filter({ contextValue }) {   
//  const { filterBrand } = contextValue;

 const { filterBrand, dispatch } = useFilterContext();
 const filterList = filterBrand.filterList;
 const filterSort = filterBrand.sortList?filterBrand.sortList:[];


      return (
        <div>      
          {filterList.map((filter, index) => (          
          <span key={index} className="gtt-badge-white mr-2">{filter.brand_name} <i className='fa fa-close' onClick={()=>{
            let stats='REMOVE_FILTER';
            dispatch({ type: stats, brandName: filter.brand_name });
          }}></i>
          </span>         
        ))}

{
filterSort.length>0 ? 
            <span  className="gtt-badge-white mr-2">{filterSort[0].sort_name} <i className='fa fa-close' onClick={()=>{
              let stats='REMOVE_FILTER';
              dispatch({ type: stats,sortId:filterSort[0].sort_id,sortName: filterSort[0].sort_name });
            }}></i>
            </span> :""   
}


          {(filterList.length>0 || filterSort.length>0) ? 
                (<span className="gtt-badge-clear" onClick={()=>{
                  let stats='CLEAR_ALL';
                  dispatch({ type: stats, brandName: "" });
                }}>
                  Clear All</span>):""
           }
        </div>
      );
}
export default Filter