import React, { useEffect, useRef, useState } from 'react'



function SubCategoryList({category,filteredData}) {



  const containerRef = useRef(null);
  const [showLeftButton, setShowLeftButton] = useState(false);
  const [showRightButton, setShowRightButton] = useState(true);


  const scrollLeft = () => {
    if (containerRef.current) {
      containerRef.current.scrollLeft -= 200; // Adjust the scrolling amount
    }
  };

  const scrollRight = () => {
    if (containerRef.current) {
      containerRef.current.scrollLeft += 200; // Adjust the scrolling amount
    }
  };

  const handleScroll = () => {
    if (containerRef.current) {
      const scrollPosition = containerRef.current.scrollLeft;
      setShowLeftButton(scrollPosition > 0);
      setShowRightButton(scrollPosition < containerRef.current.scrollWidth - containerRef.current.clientWidth);
    }
  };

  return (


        <div className='gtt-subcategory-div'>
              <a className={`gtt-scroll-button left `}  onClick={scrollLeft} ><i className="fa fa-angle-left fa-2x"></i></a>
              <div className="gtt-subcategory-container"  ref={containerRef} onScroll={handleScroll}>

                    
                      <div className="gtt-subcategory-content">
                      {filteredData.map(item => (
                      <a key={item.id} href='#'>{item.cat_name}</a>     
                          ))}
                      </div>    
                  
              </div>
           <a className={`gtt-scroll-button right`} onClick={scrollRight}><i className="fa fa-angle-right fa-2x"></i></a>
           </div>

  
  )
}

export default SubCategoryList