import React from 'react'
import CartProductCard from '../Components/CartProductCard'

import { cartItems,productData} from '../Data';
function Favorites() {
  return (
    <div>

<div className='row mb-5 mt-2'>
                    <div className='col-md-12 col-sm-12'>
                            <div className='cart-head'>
                                My Favorites
                            </div>
                            <div>
                                 {cartItems.map((item, index) => (
                                        <CartProductCard  key={index} item={item} favorateflg={true}/>
                                 ))}
                            </div>


                    </div>
                  
            </div>
    </div>
  )
}

export default Favorites