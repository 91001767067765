import React, { useState } from 'react'
import AccountRegister from './AccountRegister';
import Shipping from './Shipping';
import Payment from './Payment';
import Confirm from './Confirm';

function PlaceOrderBreadcrums({breadcrumsorder}) {

  const [currentStep, setCurrentStep] = useState(0);
  const handleBreadcrumbClick = (index) => {
    setCurrentStep(index);
  };

  return (
    <div>
         <div className='cart-head'>
                            <div className='header-bread'>
                                 {breadcrumsorder.map((item, index) => (
                                        <a href='#'  key={index} onClick={() => handleBreadcrumbClick(index)}>
                                           {currentStep === index?<i className="fa fa-circle-check"></i>:<i className="fa fa-circle"></i>}  <span>{item.label}</span> 
                                           </a>
                                 ))}  
                                 </div>     
         </div>

         {currentStep === 0 && <AccountRegister updateStep={(step) => setCurrentStep(step)}/>}
         {currentStep === 1 && <Shipping   updateStep={(step) => setCurrentStep(step)}/>}
         {currentStep === 2 && <Payment   updateStep={(step) => setCurrentStep(step)}/>}
         {currentStep === 3 && <Confirm  updateStep={(step) => setCurrentStep(step)}/>}
         
    </div>



  )
}

export default PlaceOrderBreadcrums