import React from 'react'




import CarouselMulti from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

import 'animate.css';



import { brandImages } from '../Data';
function BrandSlide() {


    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1200 },
            items: 12,
            slidesToSlide: 1, // Number of slides to scroll
            partialVisibilityGutter: 10, // Space between slides
        },
        tablet: {
            breakpoint: { max: 1200, min: 600 },
            items: 6,
            slidesToSlide: 1, // Number of slides to scroll
            partialVisibilityGutter: 30, // Space between slides
        },
        mobile: {
            breakpoint: { max: 600, min: 0 },
            items: 4,
            slidesToSlide: 1, // Number of slides to scroll
            partialVisibilityGutter: 20, // Space between slides
        },
    };
  return (


    <div className='brand-multi-slide' >

            <CarouselMulti
                responsive={responsive}
                additionalTransfrom={0}
                arrows
                autoPlay
                autoPlaySpeed={3000}
                //  customTransition={fadeTransition}// Customize transition speed to 1 second           
                centerMode={false}
                className=''
                containerClass='container-with-dots'
                dotListClass=''
                draggable
                focusOnSelect={false}
                infinite
                itemClass=''
                keyBoardControl
                minimumTouchDrag={80}
                renderButtonGroupOutside={false}
                renderDotsOutside={false}

                sliderClass=''
                slidesToSlide={1}
                swipeable
            >
                {brandImages.map((image, index) => (
                     <div key={index} className='brand-img-box' >
                        <a href='#'>
                            <div className="catgory-ellipse" >
                                {image ? (
                                    <img
                                        className="d-block w-100  "
                                        src={image.img_url}
                                        alt="" />
                                ) : null}
                            </div>
                            
                        </a>
                    </div>
                ))}
            </CarouselMulti>

    </div>
  )
}

export default BrandSlide