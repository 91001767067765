import React from 'react'

import { cartItems,productData} from '../Data';
import CartProductCard from '../Components/CartProductCard';
import Applycouponcode from '../Components/Applycouponcode';
import ShowHeadingList from '../Components/ShowHeadingList';
import MultiCarousalSlid from '../Components/MultiCarousalSlid';
import { useNavigate } from 'react-router-dom';
import OrderSummary from '../Components/OrderSummary';

function CheckOut() {

  const navigate = useNavigate();

  return (
    <div >


              <div className="cart-tot-mob"> 
                    <div> Cart <span>(10 items)</span></div>
                    <div>AED &nbsp;<span className='order-summary-item-total-res'>2045.00</span></div>
              </div>
             <div className='row'>
                    <div className='col-md-8 col-sm-12'>
                            <div className='cart-head'>
                                My Cart
                            </div>
                            <div>
                                 {cartItems.map((item, index) => (
                                        <CartProductCard  key={index} item={item}/>
                                 ))}
                            </div>


                    </div>
                    <div className='col-md-4 col-sm-12'>
                           <OrderSummary coupon={1} checkbtn={1}/>
                    </div>
            </div>

                      <div style={{ backgroundColor: '#EFF9FF' }}>
                        <ShowHeadingList heading={'Related Items'} />
                        <div className='product-carousel-xl' >
                          <MultiCarousalSlid productData={productData} />
                        </div>
                      </div>

    </div>
  )
}

export default CheckOut