import React from 'react'
import { catgoryData } from '../Data';

import { NavLink ,Link } from 'react-router-dom';

function LeftPaneCategory() {
  return (
    <div className=''>
             <h6>Category</h6>
             {catgoryData.map((category, index) => (
                 
                        <NavLink  to={`/product/${category.tag_name}`} className='leftpane-category'   key={index}    >                        
                            <span>
                                {category.cat_name}
                            </span>
                        </NavLink>
                  
                ))}

   </div>
  )
}

export default LeftPaneCategory