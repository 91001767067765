import React, { createContext, useContext, useReducer ,useState} from 'react';
import filterReducer from '../Reducer/FilterReducer'; // Replace with correct path

const FilterContext = createContext();

const initialState = {
  filterList: []
};



export function FilterProvider({ children }) {
  const [filterBrand, dispatch] = useReducer(filterReducer, initialState); 
  return (
    <FilterContext.Provider value={{ filterBrand, dispatch }}>
      {children}
    </FilterContext.Provider>
  );
}


export function useFilterContext() {

  return useContext(FilterContext);
}