import React from 'react'
import LeftPaneCategory from './LeftPaneCategory'
import LeftPaneBrand from './LeftPaneBrand'
import LeftPanePriceRange from './LeftPanePriceRange'


function LeftSidePane() {
    return (
        <div className="gtt-panel">
            <div className="gtt-panel-header">
           
            </div>
            <div className="gtt-panel-content">
                    <LeftPaneCategory/>
                    <hr className="gtt-hr"></hr>
                    <LeftPaneBrand/>
                    <hr className="gtt-hr"></hr>

                    <LeftPanePriceRange/>        
                    <hr className="gtt-hr"></hr>        
            </div>
        </div>
    )
}

export default LeftSidePane