import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';

function CreateAccount() {
    const [showPassword, setShowPassword] = useState(false);
    const navigate = useNavigate();

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };


    return (
        <div>
            <div className='login'>
                <div className='login-div'>
                    <h6>Create an account</h6>
                    <form className="row g-3 needs-validation" novalidate>
                        <div className="col-md-6">
                            <label for="validationCustom01" className="form-label">First name</label>
                            <input type="text" className="form-control form-control-sm" id="validationCustom01" value="" required />

                        </div>
                        <div className="col-md-6">
                            <label for="validationCustom01" className="form-label">Last name</label>
                            <input type="text" className="form-control form-control-sm" id="validationCustom01" value="" required />

                        </div>
                        <div className="col-md-12">
                            <label for="validationCustom01" className="form-label">Email</label>
                            <input type="text" className="form-control form-control-sm" id="validationCustom01" value="" required />

                        </div>
                        <div className="col-md-12">
                            <label for="validationCustom01" className="form-label">Mobile Number</label>
                            <input type="text" className="form-control form-control-sm" id="validationCustom01" value="" required />

                        </div>
                        <div className="col-md-6 ">
                            <label for="validationCustom01" className="form-label">Password</label>

                            <div className="input-group has-validation">

                                <input type={showPassword ? 'text' : 'password'} className="form-control form-control-sm" id="validationCustomUsername" aria-describedby="inputGroupPrepend" required />
                                <div className="invalid-feedback">
                                    Please choose a username.
                                </div>
                                <span className="input-group-text" onClick={togglePasswordVisibility} >{showPassword ? <i className="fa fa-eye"></i> : <i className="fa fa-eye-slash"></i>} </span>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <label for="validationCustom01" className="form-label">Confirm Password</label>
                            <input type="password" className="form-control form-control-sm" id="validationCustom01" value="" required />

                        </div>
                        <span >Use 8 or more characters with a mix of letters, numbers & symbols</span>
                        <div className="col-md-12"></div>
                        <div className="col-md-6">
                            <a href='#' onClick={() => navigate('/login')} className='createbtn text-black'> Sign In Instead</a>
                        </div>
                        <div className="col-md-6 ">
                            <a href='#' className='signinbtn btn btn-sm'> Create</a>
                        </div>
                    </form>


                </div>
            </div>

        </div>
    )
}

export default CreateAccount