import React from 'react'

function Shipping(props) {


    const handleSomeAction = () => {    
        // Update the current step by calling the updateStep function
        props.updateStep(2); // This will set the step to 1 (or any other step you want)
      };


    return (
        <div>

<div className='account-reg'>

<div className="checkout-head" >
                    <h6>Shipping Address</h6>
                </div>

            <form className="row g-3 needs-validation" novalidate>
            <div className="col-md-6"></div>
             <div className="col-md-6">
                    <select className="form-select form-select-sm" aria-label="Default select example">
                                <option selected>Select Saved Address</option>
                                <option value="1">One</option>
                                <option value="2">Two</option>
                                <option value="3">Three</option>
                                </select>

                    </div>
                    
                <div className="col-md-6">
                    <label for="validationCustom01" className="form-label">First name</label>
                    <input type="text" className="form-control form-control-sm" id="validationCustom01" value="" required />

                </div>

                <div className="col-md-6">
                    <label for="validationCustom01" className="form-label">Last name</label>
                    <input type="text" className="form-control form-control-sm" id="validationCustom01" value="" required />

                </div>
                <div className="col-md-6">
                    <label for="validationCustom01" className="form-label">Email</label>
                    <input type="text" className="form-control form-control-sm" id="validationCustom01" value="" required />

                </div>
                <div className="col-md-6">
                    <label for="validationCustom01" className="form-label">Mobile Number</label>
                    <input type="text" className="form-control form-control-sm" id="validationCustom01" value="" required />

                </div>
             

                <div className="col-md-6">
                    <label for="validationCustom01" className="form-label">Address</label>
                    <input type="text" className="form-control form-control-sm" id="validationCustom01" value="" required />

                </div>
                <div className="col-md-6">
                    <label for="validationCustom01" className="form-label">Street</label>
                    <input type="text" className="form-control form-control-sm" id="validationCustom01" value="" required />

                </div>
                <div className="col-md-12">
                    <label for="validationCustom01" className="form-label">Location /Landmark</label>
                    <input type="text" className="form-control form-control-sm" id="validationCustom01" value="" required />

                </div>
             
                <div className="col-md-6">
                    <label for="validationCustom01" className="form-label">Town/City</label>
                    <input type="text" className="form-control form-control-sm" id="validationCustom01" value="" required />

                </div>

                <div className="col-md-6">
                    <label for="validationCustom01" className="form-label">State</label>
                    <input type="text" className="form-control form-control-sm" id="validationCustom01" value="" required />

                </div>
                <div className="col-md-6">
                    <label for="validationCustom01" className="form-label">Country</label>
                    <input type="text" className="form-control form-control-sm" id="validationCustom01" value="" required />

                </div>
                <div className="col-md-6">
                    <label for="validationCustom01" className="form-label">Zip Code</label>
                    <input type="text" className="form-control form-control-sm" id="validationCustom01" value="" required />

                </div>

                <div className="col-md-12">
                    <label for="validationCustom01" className="form-label">Order Note</label>
                    <textarea type="text" className="form-control form-control-sm" id="validationCustom01" value="" required />

                </div>

                <div className="col-md-12">

                    <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                        <button onClick={handleSomeAction} className="btn btn-sm btn-primary me-md-2" type="button">SAVE & CONTINUE</button>
                    </div>

                </div>

            </form>
</div>
        </div>
    )
}

export default Shipping