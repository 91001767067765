
import truckSvg from './images/eligible/truck.svg';
import freereturnSvg from './images/eligible/freereturn.svg';
import freeassemblySvg from './images/eligible/freeassembly.svg';
import warrantySvg from './images/eligible/warranty.svg';
import cashondeliverySvg from './images/eligible/cashondelivery.svg';



export const brandData = [
  { id: 1, brand_name: 'Namson', tag_name: 'Namson', img_url: '', redirect_url: '#' },
  { id: 2, brand_name: 'Gtt', tag_name: 'Gtt', img_url: require('./images/temp/cat-2.png'), redirect_url: '#' },
  { id: 3, brand_name: 'Licota', tag_name: 'Licota', img_url: require('./images/temp/cat-3.png'), redirect_url: '#' },
  { id: 4, brand_name: 'Havels', tag_name: 'Havels', img_url: require('./images/temp/cat-4.png'), redirect_url: '#' },
  { id: 5, brand_name: 'Proxxon', tag_name: 'Proxxon', img_url: require('./images/temp/cat-5.png'), redirect_url: '#' },
  { id: 6, brand_name: 'Suntech', tag_name: 'Suntech', img_url: require('./images/temp/cat-6.png'), redirect_url: '#' },
  { id: 7, brand_name: 'Campmate', tag_name: 'Campmate', img_url: require('./images/temp/cat-7.png'), redirect_url: '#' },
  { id: 8, brand_name: 'Daewoo', tag_name: 'Daewoo', img_url: require('./images/temp/cat-7.png'), redirect_url: '#' },
  { id: 9, brand_name: 'Mega', tag_name: 'Mega', img_url: require('./images/temp/cat-7.png'), redirect_url: '#' },
  { id: 10, brand_name: 'Claber', tag_name: 'Claber', img_url: require('./images/temp/cat-7.png'), redirect_url: '#' },
  { id: 11, brand_name: 'Oleomac', tag_name: 'Oleomac', img_url: require('./images/temp/cat-7.png'), redirect_url: '#' },
  { id: 12, brand_name: 'meclube', tag_name: 'meclube', img_url: require('./images/temp/cat-7.png'), redirect_url: '#' },
  { id: 13, brand_name: 'meclube', tag_name: 'meclube', img_url: require('./images/temp/cat-7.png'), redirect_url: '#' },
  { id: 14, brand_name: 'meclube', tag_name: 'meclube', img_url: require('./images/temp/cat-7.png'), redirect_url: '#' },
  { id: 15, brand_name: 'meclube', tag_name: 'meclube', img_url: require('./images/temp/cat-7.png'), redirect_url: '#' },
  { id: 16, brand_name: 'meclube', tag_name: 'meclube', img_url: require('./images/temp/cat-7.png'), redirect_url: '#' },
  { id: 17, brand_name: 'meclube', tag_name: 'meclube', img_url: require('./images/temp/cat-7.png'), redirect_url: '#' },
  { id: 18, brand_name: 'meclube', tag_name: 'meclube', img_url: require('./images/temp/cat-7.png'), redirect_url: '#' },
  { id: 19, brand_name: 'meclube', tag_name: 'meclube', img_url: require('./images/temp/cat-7.png'), redirect_url: '#' },
  { id: 20, brand_name: 'meclube', tag_name: 'meclube', img_url: require('./images/temp/cat-7.png'), redirect_url: '#' },
  { id: 21, brand_name: 'meclube', tag_name: 'meclube', img_url: require('./images/temp/cat-7.png'), redirect_url: '#' },
  { id: 12, brand_name: 'meclube', tag_name: 'meclube', img_url: require('./images/temp/cat-7.png'), redirect_url: '#' },
  { id: 23, brand_name: 'meclube', tag_name: 'meclube', img_url: require('./images/temp/cat-7.png'), redirect_url: '#' },
  { id: 24, brand_name: 'meclube', tag_name: 'meclube', img_url: require('./images/temp/cat-7.png'), redirect_url: '#' },
  { id: 25, brand_name: 'meclube', tag_name: 'meclube', img_url: require('./images/temp/cat-7.png'), redirect_url: '#' },

];



export const catgoryData = [
  { id: 1, cat_name: 'Deals & Offers', tag_name: 'DealsOffers', img_url: '', redirect_url: '#' },
  { id: 2, cat_name: 'For Home', tag_name: 'forhome', img_url: require('./images/temp/cat-2.png'), redirect_url: '#' },
  { id: 3, cat_name: 'DIY & Tools', tag_name: 'DIYTools', img_url: require('./images/temp/cat-3.png'), redirect_url: '#' },
  { id: 4, cat_name: 'Camping & Outdoor', tag_name: 'CampingOutdoor', img_url: require('./images/temp/cat-4.png'), redirect_url: '#' },
  { id: 5, cat_name: 'Electrical', tag_name: 'electrical', img_url: require('./images/temp/cat-5.png'), redirect_url: '#' },
  { id: 6, cat_name: 'Car & Garage', tag_name: 'cargurage', img_url: require('./images/temp/cat-6.png'), redirect_url: '#' },
  { id: 7, cat_name: 'Garden', tag_name: 'garden', img_url: require('./images/temp/cat-7.png'), redirect_url: '#' },
  { id: 8, cat_name: 'Kids & Sports', tag_name: 'kidssports', img_url: require('./images/temp/cat-7.png'), redirect_url: '#' },
  { id: 9, cat_name: 'Kids & Sports', tag_name: 'kidssports', img_url: require('./images/temp/cat-7.png'), redirect_url: '#' },
  { id: 10, cat_name: 'Kids & Sports', tag_name: 'kidssports', img_url: require('./images/temp/cat-7.png'), redirect_url: '#' },
  { id: 11, cat_name: 'Kids & Sports', tag_name: 'kidssports', img_url: require('./images/temp/cat-7.png'), redirect_url: '#' },
  { id: 12, cat_name: 'Kids & Sports', tag_name: 'kidssports', img_url: require('./images/temp/cat-7.png'), redirect_url: '#' },
];


export const subcatgoryData = [
  { id: 1, cat_name: 'Mixi', tag_name: 'Mixi', parent_name: 'For Home', img_url: require('./images/temp/cat-2.png'), redirect_url: '#' },
  { id: 2, cat_name: 'Oven', tag_name: 'Oven', parent_name: 'For Home', img_url: require('./images/temp/cat-2.png'), redirect_url: '#' },
  { id: 3, cat_name: 'Toaster', tag_name: 'Toaster', parent_name: 'For Home', img_url: require('./images/temp/cat-2.png'), redirect_url: '#' },
  { id: 4, cat_name: 'Colth Dryer', tag_name: 'Clothdryer', parent_name: 'For Home', img_url: require('./images/temp/cat-2.png'), redirect_url: '#' },
  { id: 5, cat_name: 'Brushes', tag_name: 'Brushes', parent_name: 'For Home', img_url: require('./images/temp/cat-2.png'), redirect_url: '#' },
  { id: 6, cat_name: 'Cloth', tag_name: 'Cloth', parent_name: 'For Home', img_url: require('./images/temp/cat-2.png'), redirect_url: '#' },
  { id: 7, cat_name: 'Insect Killer', tag_name: 'InsectKiller', parent_name: 'For Home', img_url: require('./images/temp/cat-2.png'), redirect_url: '#' },

];


export const headingData = [
  { id: 1, heading_name: 'Deals of the day', img_url: [require('./images/temp/ban-2.png'), require('./images/temp/ban-3.png'), require('./images/temp/ban-4.png')], redirect_url: '#' },
  { id: 2, heading_name: 'Recomended Products', img_url: [require('./images/temp/banner-1.png')], redirect_url: '#' },

];

export const productData = [
  { id: 1, itm_name: 'Deals of the day', img_url: '', redirect_url: '#' },
  { id: 2, itm_name: 'Recomended Products', img_url: require('./images/temp/cat-2.png'), redirect_url: '#' },
  { id: 3, itm_name: 'Deals of the day', img_url: '', redirect_url: '#' },
  { id: 4, itm_name: 'Recomended Products', img_url: require('./images/temp/cat-2.png'), redirect_url: '#' },
  { id: 5, itm_name: 'Deals of the day', img_url: '', redirect_url: '#' },
  { id: 6, itm_name: 'Recomended Products', img_url: require('./images/temp/cat-2.png'), redirect_url: '#' },
  { id: 7, itm_name: 'Deals of the day', img_url: '', redirect_url: '#' },
  { id: 8, itm_name: 'Recomended Products', img_url: require('./images/temp/cat-2.png'), redirect_url: '#' }

];

export const productImgData = [
  { id: 1, itm_name: 'Recomended Products', img_url: require('./images/product/6291116278177_1.jpg'), redirect_url: '#' },
  { id: 2, itm_name: 'Recomended Products', img_url: require('./images/product/6291116278177_2.jpg'), redirect_url: '#' },
  { id: 3, itm_name: 'Recomended Products', img_url: require('./images/product/6291116278177_3.jpg'), redirect_url: '#' },
  { id: 4, itm_name: 'Recomended Products', img_url: require('./images/product/6291116278177_4.jpg'), redirect_url: '#' },
  { id: 5, itm_name: 'Recomended Products', img_url: require('./images/product/6291116278177_5.jpg'), redirect_url: '#' },
  { id: 6, itm_name: 'Recomended Products', img_url: require('./images/product/6291116278177_6.jpg'), redirect_url: '#' },




];
export const colours = [
  { id: 1, colors: '#ffffff' },
  { id: 2, colors: '#ff3a3a' },
  { id: 3, colors: '#23e9d1' },
  { id: 4, colors: '#f7fa59' },
];


export const quickLink = [
  { id: 1, link_name: 'Blog', img_url: '', redirect_url: '#' },
  { id: 2, link_name: 'Career', img_url: require('./images/temp/cat-2.png'), redirect_url: '#' },
  { id: 3, link_name: 'Company profile', img_url: require('./images/temp/cat-3.png'), redirect_url: '#' },
  { id: 4, link_name: 'Bulk order', img_url: require('./images/temp/cat-4.png'), redirect_url: '#' },
  { id: 5, link_name: 'Contact Us', img_url: require('./images/temp/cat-5.png'), redirect_url: '#' },
  { id: 6, link_name: 'Login', img_url: require('./images/temp/cat-6.png'), redirect_url: '#' },
  { id: 7, link_name: 'Garden', img_url: require('./images/temp/cat-7.png'), redirect_url: '#' },
  { id: 8, link_name: 'Return order', img_url: require('./images/temp/cat-7.png'), redirect_url: '#' },
];


export const categorybanner = [
  { id: 1, itm_name: 'Deals of the day', img_url: require('./images/temp/banner-cat-1.png'), redirect_url: '#' },
  { id: 2, itm_name: 'Gorilla', img_url: require('./images/temp/banner-cat-2.png'), redirect_url: '#' },
  { id: 2, itm_name: 'Licota', img_url: require('./images/temp/banner-cat-3.png'), redirect_url: '#' },
];


export const resources = [
  { id: 1, link_name: 'Contact Us', img_url: '', redirect_url: '#' },
  { id: 2, link_name: 'Payment Policy', img_url: require('./images/temp/cat-2.png'), redirect_url: '#' },
  { id: 3, link_name: 'Return Policy', img_url: require('./images/temp/cat-3.png'), redirect_url: '#' },
  { id: 4, link_name: 'Privacy Policy', img_url: require('./images/temp/cat-4.png'), redirect_url: '#' },
  { id: 5, link_name: 'Terms & Conditions', img_url: require('./images/temp/cat-5.png'), redirect_url: '#' },
  { id: 6, link_name: 'Delivery info', img_url: require('./images/temp/cat-6.png'), redirect_url: '#' },
];





export const eligibleItem = [
  { id: 1, text: 'Free Delivery', img_url: truckSvg, redirect_url: '#' },
  { id: 2, text: 'Free Return', img_url: freereturnSvg, redirect_url: '#' },
  { id: 3, text: 'Free Assembly', img_url: freeassemblySvg, redirect_url: '#' },
  { id: 4, text: 'Warranty', img_url: warrantySvg, redirect_url: '#' },
  { id: 5, text: 'Cash on delivery', img_url: cashondeliverySvg, redirect_url: '#' },
];







export const sociallink = [
  { id: 1, name: 'facebook', img_url: require('./images/social/fb.png'), redirect_url: '#' },
  { id: 2, name: 'instagram', img_url: require('./images/social/insta.png'), redirect_url: '#' },
  { id: 3, name: 'telegram', img_url: require('./images/social/telegram.png'), redirect_url: '#' },
];



export const brandImages = [
  { id: 1, name: 'brand1', img_url: require('./images/brands/brand-1.webp') },
  { id: 2, name: 'brand2', img_url: require('./images/brands/brand-2.webp') },
  { id: 3, name: 'brand3', img_url: require('./images/brands/brand-3.webp') },
  { id: 4, name: 'brand4', img_url: require('./images/brands/brand-4.webp') },
  { id: 5, name: 'brand5', img_url: require('./images/brands/brand-5.webp') },
  { id: 6, name: 'brand6', img_url: require('./images/brands/brand-6.webp') },
  { id: 7, name: 'brand7', img_url: require('./images/brands/brand-7.webp') },
  { id: 8, name: 'brand8', img_url: require('./images/brands/brand-8.webp') },
  { id: 9, name: 'brand9', img_url: require('./images/brands/brand-8.webp') },
  { id: 10, name: 'brand10', img_url: require('./images/brands/brand-10.webp') },
  { id: 11, name: 'brand11', img_url: require('./images/brands/brand-11.webp') },
  { id: 12, name: 'brand12', img_url: require('./images/brands/brand-12.webp') },
  { id: 13, name: 'brand13', img_url: require('./images/brands/brand-13.webp') },
  { id: 14, name: 'brand14', img_url: require('./images/brands/brand-14.webp') },

  { id: 15, name: 'brand15', img_url: require('./images/brands/brand-15.webp') },
  { id: 16, name: 'brand16', img_url: require('./images/brands/brand-16.webp') },
  { id: 17, name: 'brand17', img_url: require('./images/brands/brand-17.webp') },
  { id: 18, name: 'brand18', img_url: require('./images/brands/brand-19.webp') },
  { id: 19, name: 'brand19', img_url: require('./images/brands/brand-19.webp') },
  { id: 20, name: 'brand20', img_url: require('./images/brands/brand-20.webp') },
  { id: 21, name: 'brand21', img_url: require('./images/brands/brand-21.webp') },
  { id: 22, name: 'brand22', img_url: require('./images/brands/brand-22.webp') },
  { id: 23, name: 'brand23', img_url: require('./images/brands/brand-23.webp') },
  { id: 24, name: 'brand24', img_url: require('./images/brands/brand-24.webp') },
  { id: 25, name: 'brand25', img_url: require('./images/brands/brand-25.webp') },
  { id: 26, name: 'brand26', img_url: require('./images/brands/brand-26.webp') },
  { id: 27, name: 'brand27', img_url: require('./images/brands/brand-27.webp') },
  { id: 28, name: 'brand28', img_url: require('./images/brands/brand-28.webp') },
  { id: 29, name: 'brand29', img_url: require('./images/brands/brand-29.webp') },
  { id: 30, name: 'brand30', img_url: require('./images/brands/brand-30.webp') },
  { id: 31, name: 'brand30', img_url: require('./images/brands/brand-31.webp') },
  { id: 32, name: 'brand30', img_url: require('./images/brands/brand-32.webp') },
  { id: 33, name: 'brand30', img_url: require('./images/brands/brand-33.webp') },


  { id: 34, name: 'brand15', img_url: require('./images/brands/brand-15.webp') },
  { id: 35, name: 'brand16', img_url: require('./images/brands/brand-16.webp') },
  { id: 36, name: 'brand17', img_url: require('./images/brands/brand-17.webp') },
  { id: 37, name: 'brand18', img_url: require('./images/brands/brand-19.webp') },
  { id: 38, name: 'brand19', img_url: require('./images/brands/brand-19.webp') },
  { id: 39, name: 'brand20', img_url: require('./images/brands/brand-20.webp') },
  { id: 40, name: 'brand21', img_url: require('./images/brands/brand-21.webp') },
  { id: 41, name: 'brand22', img_url: require('./images/brands/brand-22.webp') },
  { id: 42, name: 'brand23', img_url: require('./images/brands/brand-23.webp') },
  { id: 43, name: 'brand24', img_url: require('./images/brands/brand-24.webp') },
  { id: 44, name: 'brand25', img_url: require('./images/brands/brand-25.webp') },
  { id: 45, name: 'brand26', img_url: require('./images/brands/brand-26.webp') },
  { id: 46, name: 'brand27', img_url: require('./images/brands/brand-27.webp') },
  { id: 47, name: 'brand28', img_url: require('./images/brands/brand-28.webp') },
  { id: 48, name: 'brand29', img_url: require('./images/brands/brand-29.webp') },
  { id: 49, name: 'brand30', img_url: require('./images/brands/brand-30.webp') },
  { id: 50, name: 'brand30', img_url: require('./images/brands/brand-31.webp') },
  { id: 51, name: 'brand30', img_url: require('./images/brands/brand-32.webp') },
  { id: 52, name: 'brand30', img_url: require('./images/brands/brand-33.webp') },


];


export const cartItems = [
  {
    id: 1
    , itm_title: 'Mixer Grinder'
    , itm_name: 'STAND MIXER 1100W KITCHEN MACHINE | ELECTRIC MIXER | 7L STAINLESS STEEL MIXING BOWL WITH LID | 6 SPEEDS | SAFTY SWITCH | DOUGH HOOK- BEATER- WIRE WHIP VE-192'
    , brand_name: 'Vogatronix'
    , category_name: 'Home Appliances'
    , img_url: require('./images/product/6291116278177_1.jpg')
    , freedelivery_flg: 'Y'
    , expected_delivery: '12-05-2023'
    , itm_qty: '1  '
    , sell_rate: '273.00'
    , base_rate: '372.00'
  },

  {
    id: 2
    , itm_title: 'Mixer Grinder'
    , itm_name: 'STAND MIXER 1100W KITCHEN MACHINE | ELECTRIC MIXER | 7L STAINLESS STEEL MIXING BOWL WITH LID | 6 SPEEDS | SAFTY SWITCH | DOUGH HOOK- BEATER- WIRE WHIP VE-192'
    , brand_name: 'Vogatronix'
    , category_name: 'Home Appliances'
    , img_url: require('./images/product/6291116278177_1.jpg')
    , freedelivery_flg: 'Y'
    , expected_delivery: '12-05-2023'
    , itm_qty: '2'
    , sell_rate: '273.00'
    , base_rate: '372.00'
  },
  {
    id: 3
    , itm_title: 'Mixer Grinder'
    , itm_name: 'STAND MIXER 1100W KITCHEN MACHINE | ELECTRIC MIXER | 7L STAINLESS STEEL MIXING BOWL WITH LID | 6 SPEEDS | SAFTY SWITCH | DOUGH HOOK- BEATER- WIRE WHIP VE-192'
    , brand_name: 'Vogatronix'
    , category_name: 'Home Appliances'
    , img_url: require('./images/product/6291116278177_1.jpg')
    , freedelivery_flg: 'Y'
    , expected_delivery: '12-05-2023'
    , itm_qty: '2'
    , sell_rate: '273.00'
    , base_rate: '372.00'
  },
  {
    id: 4
    , itm_title: 'Mixer Grinder'
    , itm_name: 'STAND MIXER 1100W KITCHEN MACHINE | ELECTRIC MIXER | 7L STAINLESS STEEL MIXING BOWL WITH LID | 6 SPEEDS | SAFTY SWITCH | DOUGH HOOK- BEATER- WIRE WHIP VE-192'
    , brand_name: 'Vogatronix'
    , category_name: 'Home Appliances'
    , img_url: require('./images/product/6291116278177_1.jpg')
    , freedelivery_flg: 'Y'
    , expected_delivery: '12-05-2023'
    , itm_qty: '2'
    , sell_rate: '273.00'
    , base_rate: '372.00'
  },

]

export const optionsSelect = [
  { value: 'RECOMMENDED', label: 'RECOMMENDED' },
  { value: 'HIGH-TO-LOW', label: 'PRICE:HIGH TO LOW' },
  { value: 'LOW-TO-HIGH', label: 'PRICE:LOW TO HIGH' },
  { value: 'NEW-ARRIVALS', label: 'NEW ARRIVALS' },
  { value: 'BEST-OFFER', label: 'BEST OFFER' },
];
export const pageShowNo = [
  { value: '20', label: '20 Per Page' },
  { value: '50', label: '50 Per Page' },
  { value: '100', label: '100 Per Page' },
];
export const breadcrumsorder = [
  { value: '1', label: 'Account' },
  { value: '2', label: 'Shipping' },
  { value: '3', label: 'Payment' },
  { value: '4', label: 'Confirmation' },
];


export const profileorder = [
  { value: '1', label: 'Your orders', url: 'myorder' },
  { value: '2', label: 'Return', url: 'returns' },
  { value: '3', label: 'Track your orders', url: 'trackorder' },
  { value: '4', label: 'Subscribe & Save', url: 'subscribe' }
];
export const profileaccount = [
  { value: '5', label: 'Manage Your Profile', url: 'loginsecurity' },
  { value: '6', label: 'Manage Address Book', url: 'addressbook' },
  { value: '7', label: 'Favorites', url: 'favorites' },
  { value: '8', label: 'Bank settings', url: 'bankdtls' },
  { value: '9', label: 'Help & Support', url: 'support' },
  { value: '10', label: 'Delete Your Account', url: 'deleteaccount' },
 

];


export const ordermaster = [
  {
    order_id: 1
    , tran_no: 'GTTORD000029'
    , bill_no: '203201'
    , tran_date: '23-05-2023'
    , expected_delivery: '28-05-2023'
    , total_amount: '350.00'
    , total_itm: '10'
    , address: "Nishad M M\nmanakkathodi\nduba "
    , stats: "Processing"
  },
  {
    order_id: 2
    , tran_no: 'GTTORD000030'
    , bill_no: '203201'
    , tran_date: '23-05-2023'
    , expected_delivery: '28-05-2023'
    , total_amount: '50.00'
    , total_itm: '1'
    , address: "Nishad M M\nmanakkathodi\nduba"
    , stats: "Pending"
  },
  {
    order_id: 3
    , tran_no: 'GTTORD000031'
    , bill_no: '203201'
    , tran_date: '23-05-2023'
    , expected_delivery: '28-05-2023'
    , total_amount: '4500.00'
    , total_itm: '5'
    , address: "Nishad M M\nmanakkathodi\nduba"
    , stats: "Completed"
  },
]

export const orderhistory = [
  {
    order_his: 1
    , order_id: 1
    , tran_no: 'GTTORD000029'
    , tran_date: '23-05-2023'
    , description: "Order received"
  },
  {
    order_his: 2
    , order_id: 1
    , tran_no: 'GTTORD000029'
    , tran_date: '24-05-2023'
    , description: "Order initaiated"
  },
  {
    order_his: 3
    , order_id: 1
    , tran_no: 'GTTORD000029'
    , tran_date: '24-05-2023'
    , description: "Order despatched from warehouse"
  },
  {
    order_his: 3
    , order_id: 1
    , tran_no: 'GTTORD000029'
    , tran_date: '24-05-2023'
    , description: "Order allocted to delivery team"
  },
  {
    order_his: 3
    , order_id: 1
    , tran_no: 'GTTORD000029'
    , tran_date: '24-05-2023'
    , description: "Order on the vehicle"
  },
  {
    order_his: 3
    , order_id: 1
    , tran_no: 'GTTORD000029'
    , tran_date: '24-05-2023'
    , description: "Delivered"
  },




]
export const addressBook = [
  {
     id:1
    , address: "Nishad M M\nmanakkathodi\ndubai"
  },
  {
    id:2
   , address: "Nishad M M\nmanakkathodi\ndubai"
 },
 {
  id:3
 , address: "Nishad M M\nmanakkathodi\ndubai"
},
{
  id:4
 , address: "Nishad M M\nmanakkathodi\ndubai"
}

]

export const companySlid = [
 
  { id: 2, link_name: 'company-2', img_url:require('./images/company/sh3.png')},
  { id: 3, link_name: 'company-3', img_url:require('./images/company/sh4.png')},
  { id: 4, link_name: 'company-4', img_url:require('./images/company/sh5.png')},
  { id: 5, link_name: 'company-5', img_url:require('./images/company/sh6.png')},
];
