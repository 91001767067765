import React from 'react'

function LoginSecurity() {
  return (
    <div>

        <div className='col-md-12 col-sm-12'>

        <div className='cart-head'>
               Login & Security              
            </div>
        </div>
        <div className='row pt-2'>

        <div className='col-md-12 col-sm-12 mb-2'>

         <div className='card p-2'>
            <div className="mb-3 row">
                <label  className="col-sm-3 col-form-label">First Name</label>
                <div className="col-sm-9">
                <input type="text" className="form-control form-control-sm " />
                </div>
            </div>
            <div className="mb-3 row">
                <label className="col-sm-3 col-form-label">Last Name</label>
                <div className="col-sm-9">
                <input type="text" className="form-control form-control-sm " />
                </div>
            </div>
            <div className="mb-3 row">
            <div className="col-sm-12">
                    <button className=" subscribebtn btn btn-sm " type="button"><i className="fa fa-hand-point-right"></i> &nbsp;Click here to change </button>
                    </div>
            </div>
            </div>

        </div>

        <div className='col-md-6 col-sm-12'>

            <div className='card p-2'>
            <div className="mb-3 row">
                <label className="col-sm-3 col-form-label">Email</label>
                <div className="col-sm-9">
                <input type="text" readOnly className="form-control-plaintext"  value="email@example.com" />
                </div>
            </div>
            <div className="mb-3 row">
                <label className="col-sm-3 col-form-label">Change Email</label>
                <div className="col-sm-9">
                <input type="password" className="form-control form-control-sm " id="inputPassword"/>
                </div>
            </div>
            <div className="mb-3 row">
            <div className="col-sm-12">
                    <button className=" subscribebtn btn btn-sm " type="button"><i className="fa fa-hand-point-right"></i> &nbsp;Click here to change </button>
                    </div>
            </div>
            </div>
        </div>

        <div className='col-md-6 col-sm-12'>

            <div className='card p-2'>
            <div className="mb-3 row">
                <label  className="col-sm-3 col-form-label">Mobile No</label>
                <div className="col-sm-9">
                <input type="text" readOnly className="form-control-plaintext" id="staticEmail" value="0561454451"/>
                </div>
            </div>
            <div className="mb-3 row">
                <label   className="col-sm-3 col-form-label">Change Mobile No</label>
                <div className="col-sm-9">
                <input type="password" className="form-control form-control-sm " id="inputPassword"/>
                </div>
            </div>
            <div className="mb-3 row">
                 <div className="col-sm-12">
                    <button className=" subscribebtn btn btn-sm " type="button"><i className="fa fa-hand-point-right"></i> &nbsp;Click here to change </button>
                    </div>
            </div>
            </div>
        </div>

        <div className='col-md-12 col-sm-12 mt-2'>

        <div className='card p-4'>

            <div className="mb-3 row">
                <label   className="col-sm-3 col-form-label">Old Password</label>
                <div className="col-sm-9">
                <input type="password" className="form-control form-control-sm " id="inputPassword"/>
                </div>
            </div>

            <div className="mb-3 row">
                <label  className="col-sm-3 col-form-label">New Password</label>
                <div className="col-sm-9">
                <input type="password" className="form-control form-control-sm " id="inputPassword"/>
                </div>
            </div>
            
            <div className="mb-3 row">
                 <div className="col-sm-12">
                    <button className=" subscribebtn btn btn-sm " type="button"><i className="fa fa-hand-point-right"></i> &nbsp;Click here to change </button>
                    </div>
            </div>


            </div>
        </div>
        
        </div>

    </div>
  )
}

export default LoginSecurity