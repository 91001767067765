import React, { useState } from 'react'
import ItemShow from './ItemShow'
import Filter from './Filter'



function CenterPane() {

  return (    
    <div>
            <div className="gtt-panel-center">
                <div className="gtt-panel-header-center" style={{color:'#ffff'}}>
                             
                </div>
                <div className="gtt-panel-content-center p-1">                
                  <ItemShow />
                </div>
            </div>
    </div>
    
  )
}

export default CenterPane
