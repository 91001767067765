import React from 'react'



import ban1 from '../images/temp/ban-1.png';
import ban2 from '../images/temp/ban-2.png';
import ban3 from '../images/temp/ban-3.png';
import ban4 from '../images/temp/ban-4.png';
import ban5 from '../images/temp/ban-5.png';
import ban6 from '../images/temp/ban-6.png';
import ban7 from '../images/temp/ban-7.png';



function Adslist() {

   

    return (

        <div> 


        <div className='adslist-master pt-3 p-2'>

            <div className='row gx-2 mb-2'>
                <div className='col-6'>
                    <div className='card-gtt-1'>

                    <img
                        className="d-block w-100  "
                        src={ban1}
                        alt='ban1'/>

                    </div>
                </div>
                <div className='col-3'>
                    <div className='card-gtt-1'>
                        
                    <img
                        className="d-block w-100  "
                        src={ban2}
                        alt='ban2'/>
                    </div>

                </div>
                <div className='col-3'>

                    <div className='card-gtt-1'>

                    <img
                        className="d-block w-100  "
                        src={ban3}
                        alt='ban3'/>
                    </div>
                </div>
            </div>

            <div className='row  gx-2 mb-2'>
                <div className='col-3'>
                    <div className='card-gtt-1'>

                    <img
                        className="d-block w-100  "
                        src={ban4}
                        alt='ban4'/>

                    </div>
                </div>
                <div className='col-3'>
                    <div className='card-gtt-1'>
                        
                    <img
                        className="d-block w-100  "
                        src={ban5}
                        alt='ban5'/>
                    </div>

                </div>
                <div className='col-3'>

                    <div className='card-gtt-1'>

                    <img
                        className="d-block w-100  "
                        src={ban6}
                        alt='ban6'/>
                    </div>
                </div>
                <div className='col-3'>

                    <div className='card-gtt-1'>

                    <img
                        className="d-block w-100  "
                        src={ban7}
                        alt='ban7'/>
                    </div>
                </div>
            </div>

        </div>



        <div className='adslist-master-mob pt-3 p-2'>

            <div className='row gx-2 mb-2'>
                <div className='col-12'>
                    <div className='card-gtt-1'>

                    <img
                        className="d-block w-100  "
                        src={ban1}
                        alt='ban1'/>

                    </div>
                </div>
                
                
            </div>

            <div className='row  gx-2 mb-2'>
                <div className='col-6'>
                    <div className='card-gtt-1'>

                    <img
                        className="d-block w-100  "
                        src={ban4}
                        alt='ban4'/>

                    </div>
                </div>
                <div className='col-6'>
                    <div className='card-gtt-1'>
                        
                    <img
                        className="d-block w-100  "
                        src={ban5}
                        alt='ban5'/>
                    </div>

                </div>
                
                
            </div>

        </div>

        
        </div> 
    );


}

export default Adslist