import React from 'react'
import visaimg from '../images/payment/visa.svg';
import masterimg from '../images/payment/master.svg';
function Payment({ updateStep }) {

    const handleSomeAction = () => {
        // Update the current step by calling the updateStep function
        updateStep(3); // This will set the step to 1 (or any other step you want)
    };


    return (
        <div>
            <div className='account-reg'>

                <div className="checkout-head" >
                    <h6>Payment Method</h6>
                </div>
                <form className="row g-3 needs-validation" >


                    <div className="col-md-6">
                        <div className="paymentcheck form-check ">
                            <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                            <label className="form-check-label" for="flexRadioDefault1">
                                Debit Credit
                            </label>
                            <div className='cardimg'>
                                <img
                                    src={visaimg}
                                    alt='Visa'
                                />
                                <img
                                    src={masterimg}
                                    alt='Master'
                                />
                            </div>

                        </div>
                    </div>
                    <div className="col-md-6 ">
                        <div className=" form-check paymentcheck  ">
                            <input className="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault2" checked />
                            <label className="form-check-label" for="flexRadioDefault2">
                                Cash On delivery
                            </label>
                            <div className='cardimg'>
                                COD
                            </div>
                        </div>
                    </div>
                    <div className="col-md-12">
                        <label for="validationCustom01" className="form-label">Card Holder Name</label>
                        <input type="text" className="form-control form-control-sm" id="validationCustom01" value="" required />

                    </div>

                    <div className="col-md-12">
                        <label for="validationCustom01" className="form-label">Card Number</label>
                        <input type="text" className="form-control form-control-sm" id="validationCustom01" value="" required />

                    </div>
                    <div className="col-md-6">
                        <label for="validationCustom01" className="form-label">Expiry Date</label>
                        <input type="text" className="form-control form-control-sm" id="validationCustom01" value="" required placeholder='MM/YY' />

                    </div>
                    <div className="col-md-6">
                        <label for="validationCustom01" className="form-label">CVV</label>
                        <input type="text" className="form-control form-control-sm" id="validationCustom01" value="" required />

                    </div>
                    <div className="col-md-12">

                        <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                            <button onClick={handleSomeAction} className="btn btn-sm btn-primary me-md-2" type="button">SAVE & CONTINUE</button>
                        </div>

                    </div>

                </form>

            </div>
        </div>
    )
}

export default Payment