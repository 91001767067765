import React from 'react'
import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css'; 
function DeleteAccount() {


  const checkAlert = () => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'Delete address form your addressbook!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#fabf12',
      confirmButtonText: 'Yes, delete it!',
      cancelButtonColor: '#000532',
      cancelButtonText: 'No, keep it',
      showCloseButton: true,
      
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire({
            title: 'Deleted!',
            text: 'Your Address has been deleted!',
            icon: 'success',
            confirmButtonText: 'OK',
            confirmButtonColor: '#fabf12',
            
          });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire('Cancelled', 'Your imaginary file is safe :)', 'error');
      }
    });
  };

  return (
    <div>
        <div className='delete-account-div'>
                <i className="fa fa-ban fa-9x "></i><br></br>
               
                <a href='#' onClick={checkAlert} ><i className="fa fa-trash-can "></i> &nbsp; Delete My Account</a>
        </div>
        </div>
  )
}

export default DeleteAccount