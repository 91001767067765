import React from 'react'
import { useNavigate } from 'react-router-dom';

function Support() {
  const navigate = useNavigate();
  return (
    <div>
      <div className='support-call'>
       <span className='support-icon call'><i className="fa-solid fa-headset fa-4x"></i></span>
        <div>
          <h5>CONTACT</h5> 
          <span> for Assistance</span>
          <h4 className='text-primary'> +971 4 2238240</h4>
          <h4 className='text-primary'> +971 4 2722128</h4>
        </div>
      </div>
      <div className='support-mail'>
      
        <div>
          <h5>Contact Support</h5> 
          <span>  via Email</span>
          <h4 className='text-primary'>helpdesk@goldentoolsuae.ae</h4>         
        </div>
        <span className='support-icon mail'><i className="fa fa-envelope fa-4x"></i></span>
      </div>

      <div className='support-company-profile '>
       <span className='support-icon company'><i className="fa fa-chart-simple  fa-4x"></i></span>
        <div className='support-company-text'>
           <h5>ABOUT</h5> <span>  Company Info</span> <br></br>
           
           <a className='btn btn-sm' onClick={() => navigate('/company')}><i className="fa fa-hand-point-right"></i> Click Here</a>
        </div>
      </div>

    </div>
  )
}

export default Support