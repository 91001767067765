import React from 'react'
import { useNavigate } from 'react-router-dom';

function OrderCard(props) {

    const order=props.order
    const navigate = useNavigate();

    const handleMenuClick = () => {
       if(props.arrowshow)
       {
            return null
       }else if(props.trackorder){
            navigate('/profile/trackOrderdtls/'+ order.order_id);
       }else{
           navigate('/profile/myorderdtls/'+ order.order_id);
       }
      };
  return (
    <div>
            <div className='order-card'  onClick={handleMenuClick} >
                <div>
                 <a className='order-icon'><i className="fa fa-bag-shopping fa-2x"></i></a>
                </div>
                 <article>
                    <div className='text-success'> Reference No : {order.tran_no}</div>
                    <div> Date : {order.tran_date}</div>
                    <div>Invoice No : {order.bill_no}</div>
                    <div className='text-primary small '>Expected Delivery : {order.expected_delivery}</div>

                    
                 </article>
                 <article>
                  
                    <pre className='order-address'>{order.address}</pre> 
                 </article>

                 <article className='order-amount'>
                 <span className='currency-tag'>AED</span><h6  className='text-success'>{order.total_amount}</h6> 
                  <div> Items: {order.total_itm}</div>
               </article>
               <article className='order-status'>
                  
                   <div className='mb-3 text-danger'>{order.stats}</div>
                    {
                        !props.arrowshow? <div><a href="#" className='order-details-btn'> <i className="fa fa-angles-right"></i></a></div>:null
                    }
                      
                   
               </article>
            </div>

    </div>
  )
}

export default OrderCard