import React from 'react'


import CarouselMulti from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

import 'animate.css';


import product1 from '../images/temp/product-1.png';
import product2 from '../images/temp/product-2.png';



function CarousalTwo() {
    const images = [product1, product2, product1, product2];

    const responsive = {
        desktop: {
          breakpoint: { max: 3000, min: 1200 },
          items: 2,
          slidesToSlide: 3, // Number of slides to scroll
          partialVisibilityGutter: 40, // Space between slides
        },
        tablet: {
          breakpoint: { max: 1200, min: 600 },
          items: 1,
          slidesToSlide: 2, // Number of slides to scroll
          partialVisibilityGutter: 30, // Space between slides
        },
        mobile: {
          breakpoint: { max: 600, min: 0 },
          items: 1,
          slidesToSlide: 1, // Number of slides to scroll
          partialVisibilityGutter: 20, // Space between slides
        },
      };



      const fadeTransition = {
        animation: 'animate__animated animate__fadeInRight', 
        duration: 500,       
      };

      
      
    return (
        <div className='gtt-master-carousel gtt-carousel-two '>
          <CarouselMulti
            responsive={responsive}
            additionalTransfrom={0}
            arrows
            autoPlay
            autoPlaySpeed={3000}
            customTransition={fadeTransition}// Customize transition speed to 1 second           
            centerMode={false}
            className=''
            containerClass='container-with-dots'
            dotListClass=''
            draggable
            focusOnSelect={false}
            infinite
            itemClass=''
            keyBoardControl
            minimumTouchDrag={80}
            renderButtonGroupOutside={false}
            renderDotsOutside={false}
           
            sliderClass=''
            slidesToSlide={1}
            swipeable
          >
            {images.map((image, index) => (
              <div key={index} className='card card-slid  ' >
                   <img
                    className="d-block w-100  "
                    src={image}
                    alt='category'/>
              </div>
            ))}
          </CarouselMulti>
        </div>
      );

}

export default CarousalTwo