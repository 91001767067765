import React,{useState,useReducer, useEffect } from 'react'
import filterReducer from '../Reducer/FilterReducer'
import { brandData } from '../Data';
import { NavLink ,Link } from 'react-router-dom';
import {useFilterContext  } from '../ContextProvider/FilterContext';



function LeftPaneBrand() {


  const itemsPerPage = 10; // Number of items to show per page
  const [visibleItems, setVisibleItems] = useState(itemsPerPage);
  
  const handleSeeMore = () => {
    setVisibleItems(prevVisibleItems => prevVisibleItems + itemsPerPage);
  };

  const { filterBrand, dispatch } = useFilterContext();

 
  useEffect(() => {
      const avilBrand=filterBrand.filterList

      brandData.forEach((brand) => {        
        const checkbox=document.getElementById("brandchk_"+brand.id)
        if(checkbox)
        checkbox.checked = false;
      });
      
        for (const brand in avilBrand) {             
          const checkbox = document.getElementById("brandchk_"+avilBrand[brand].brand_id)                    
            if (checkbox) {
                checkbox.checked = true;
            }          
        }
  });


  const handleCheckboxChange = (event, brandName,brandId) => {
       let stats='REMOVE_FILTER';
      if (event.target.checked) {      
        stats='ADD_FILTER';
      }
     dispatch({ type: stats,brandName:brandName,brandId:brandId });
    
  };


  
    return (
        <div className='leftpane-brand'>
                 <h6>Brand</h6>
                    {brandData.slice(0, visibleItems).map((brand, index) => (
                          <div className='leftpane-brand-checkbox' key={index}>
                            <label>
                              <input type="checkbox"  id={"brandchk_"+brand.id}                                     
                                         onChange={(event) => handleCheckboxChange(event, brand.brand_name,brand.id)}
                                     />
                               {brand.brand_name}
                            </label>
                          </div>                                                         
                    ))}
                     {visibleItems < brandData.length && (
                    <button className='show-more-btn' onClick={handleSeeMore}>
                      See More
                    </button>
      )}
    
       </div>
      )
}


export default LeftPaneBrand
