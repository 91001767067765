import React, { useState } from 'react'
import { addressBook } from '../Data';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css'; 
function AddressBook() {

    const navigate = useNavigate();
    const [showSuccessAlert, setShowSuccessAlert] = useState(false);

    const checkAlert = () => {
        Swal.fire({
          title: 'Are you sure?',
          text: 'Delete address form your addressbook!',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#fabf12',
          confirmButtonText: 'Yes, delete it!',
          cancelButtonColor: '#000532',
          cancelButtonText: 'No, keep it',
          showCloseButton: true,
          
        }).then((result) => {
          if (result.isConfirmed) {
            Swal.fire({
                title: 'Deleted!',
                text: 'Your Address has been deleted!',
                icon: 'success',
                confirmButtonText: 'OK',
                confirmButtonColor: '#fabf12',
                
              });
          } else if (result.dismiss === Swal.DismissReason.cancel) {
            Swal.fire('Cancelled', 'Your imaginary file is safe :)', 'error');
          }
        });
      };
      
    return (

        <div>

            <div className='col-md-12 col-sm-12'>

                <div className='cart-head'>
                    Address Book
                </div>
            </div>


            <div className='row'>


                {addressBook.map((item, index) => (

                    <div key={index} className='col-md-6 col-sm-12'>
                        <div className='card-gtt'>
                        <i className="fa fa-address-book fa-2x"></i>
                            <pre className='order-address '>
                                {item.address}
                            </pre>
                         <div>
                            <a href='#' onClick={()=> navigate('/profile/editaddress/'+ item.id)}className='text-primary' ><i className="fa fa-edit"></i>Edit</a><br></br>
                            <a href='#' onClick={checkAlert} className='text-danger'><i className="fa fa-trash-can"></i>Remove</a>
                        </div>   
                        </div>

                    </div>

                ))}




            </div>


        </div>
    )
}

export default AddressBook