import React from 'react'
import OrderCard from '../Components/OrderCard'
import { ordermaster } from '../Data';
function MyReturns() {
  return (
    <div>
    <div className='myorder'>  
                            <div className='cart-head'>
                            Order Return
                              
                            </div>
    {ordermaster.map((item, index) => (
                                      <OrderCard  key={index}  order={item}/>
                                ))} 


        
    </div>

</div>
  )
}

export default MyReturns