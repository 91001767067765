import React, { useState } from 'react';


const ImageMagnifier = ({ smallImage, largeImage }) => {



    const [isHovered, setIsHovered] = useState(false);

    const handleMouseEnter = () => {
      setIsHovered(true);
    };
  
    const handleMouseLeave = () => {
      setIsHovered(false);
    };

  const [magnifierPosition, setMagnifierPosition] = useState({ x: 0, y: 0 });

  const handleMouseMove = (e) => {
    setIsHovered(true);
    const { left, top, width, height } = e.target.getBoundingClientRect();
    const offsetX = e.clientX - left;
    const offsetY = e.clientY - top;
   

    setMagnifierPosition({
      x: (offsetX / width) * 100,
      y: (offsetY / height) * 100,
    });
  };

  return (
    <div className="image-magnifier-container">
      <div className="small-image-container" onMouseMove={handleMouseMove}>
        <img src={smallImage} alt="Small Image"  />
      </div>

      {
        isHovered?
      
      (<div className="large-image-container">        
        <img
          src={largeImage}
          alt="Large Image"
          className={isHovered ? 'show-large' : ''}
          style={{
            transform: `translate(-${magnifierPosition.x}%, -${magnifierPosition.y}%) scale(2)`,
          }}
         
        />
      </div>):null
      }
    </div>
  );
};

export default ImageMagnifier;
