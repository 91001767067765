import React, { useEffect, useRef, useState } from 'react'


import largeImage from '../images/img1.png';
import { productImgData, colours, eligibleItem } from '../Data';

import ImageMagnifier from '../Components/ImageMagnifier';
import ItemImgSlideScroll from '../Components/ItemImgSlideScroll';
import ItemNav from '../Components/ItemNav';

import ShowHeadingList from '../Components/ShowHeadingList';
import { productData } from '../Data';
import MultiCarousalSlid from '../Components/MultiCarousalSlid';
import { Link, NavLink, useNavigate } from 'react-router-dom';


function Item() {
  const navigate = useNavigate();
  const imageSize = 400; // Initial image size in pixels

  // Construct the large image URL with the updated size
  const updatedLargeImage = largeImage.replace(/(\d+)x(\d+)/, `${imageSize}x${imageSize}`);

  const [selectedColor, setSelectedColor] = useState(null);
  const [shareUrl, setShareUrl] = useState(null);

  const [quantity, setQuantity] = useState(1);

  const handleMinusClick = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1);
    }
  };

  const handlePlusClick = () => {
    setQuantity(quantity + 1);
  };


  useEffect(() => {
    setSelectedColor(colours[0].colors); // Initially select the first color
  }, []); //


  const handleShare = async () => {


    
    if (navigator.share) {
      try {
        await navigator.share({
          url: window.location.href,
        });
      } catch (error) {
        console.error('Error sharing:', error);
      }
    } else {
      console.log('Share API not supported');
      // Implement your custom share dialog for browsers that don't support the Share API
    }
  };
  
  return (
    <div>
      <div className='item-single-div'>
        <div className='row'>
          <div className='col-md-6'>
            <ItemImgSlideScroll imglist={productImgData} />
          </div>
          <div className='col-md-6 col-sm-12'>
            <article className='item-title'>
              <div>
                <h4>Mixer Grinder</h4>
                <h6>Home Applainces</h6>
              </div>
              <div>
                <a href='#' onClick={handleShare} > <i className='fa fa-heart '></i></a>
              </div>
            </article>
            <hr className='gtt-hr'></hr>
            <article className='item-title'>
              <div>
                <p className='brand'>Vogatronix</p>
                <p>
                  STAND MIXER 1100W KITCHEN MACHINE | ELECTRIC MIXER | 7L STAINLESS STEEL MIXING BOWL WITH LID | 6 SPEEDS | SAFTY SWITCH | DOUGH HOOK- BEATER- WIRE WHIP VE-192
                </p>
              </div>

            </article>
            <div className='row'>
              <div className='col-md-6 col-sm-12'>

                <article className='item-title'>
                  <div className='price-list-div'>

                    <h5><span>AED</span>275.00</h5><div className='was-price'><span>AED</span> 320.00</div>
                  </div>

                </article>
                <article className='item-title'>
                  <div className='color-list-div'>
                    <span>Colours : </span>
                    {colours.map((item, index) => (
                      <a key={item.id} href='#' onClick={() => setSelectedColor(item.colors)} className={` ${selectedColor === item.colors ? 'selected' : ''}`} style={{
                        backgroundColor: `${item.colors}`,
                      }}> <span className='arrow-down'>&#9660;</span></a>

                    ))}
                  </div>
                </article>
                <article className='item-title'>
                  <div className='qty-list-div'>
                    <span>Quantity : </span>
                    <a href='#' className='minus' onClick={handleMinusClick} >-</a>
                    <input type='text' value={quantity} readOnly ></input>
                    <a href='#' className='plus' onClick={handlePlusClick}>+</a>
                  </div>
                </article>
                <article className='item-title'>
                  <div className='attr-list-div'>
                    <span>Availability : <a href='#' style={{ color: '#38a626', textDecoration: 'none' }}>In Stock</a></span>
                    <span>Model No : MODEL1234</span>
                    <span>Sold by : Golden Tools Trading LLC</span>
                    <span>Expected delivery : <label style={{ color: '#38a626' }}>12-05-2023</label></span>
                  </div>
                </article>
              </div>
              <div className='col-md-6 col-sm-12'>
                <div className="eligible-panel">
                  <div className="eligible-panel-header">
                    This item is eligible for
                  </div>
                  <div className="eligible-panel-content">
                    {eligibleItem.map((item, index) => (
                      <a key={item.id} href='#'>
                        <span>{item.text}</span>
                        <img
                          src={item.img_url}
                          alt={item.text}
                        />
                      </a>

                    ))}
                  </div>
                </div>

              </div>
            </div>
            <article className='item-title'>
              <div className='button-list-div'>
                <NavLink type="button" className="btn btn-sm  btn-lg btn-block addcart">Add to Cart</NavLink>
                <NavLink type="button" to={'/checkout'} className="btn btn-sm  btn-lg btn-block buynow">Buy Now</NavLink>
              </div>
            </article>
          </div>
        </div>
        <hr className='item-hr'></hr>
        <ItemNav />
        <div style={{ backgroundColor: '#EFF9FF' }}>
          <ShowHeadingList heading={'Related Items'} />
          <div className='product-carousel-xl' >
            <MultiCarousalSlid productData={productData} />
          </div>
        </div>
      </div>
      <div className='item-single-div-mob'>

        <ItemImgSlideScroll imglist={productImgData} />
       
       <div className='item-details-mob'> 
       <div className='row '>
          <div className='col-7'>
        <article className='item-title'>

          <div>
            <p className='brand'>Vogatronix</p>
            <h4>Mixer Grinder</h4>
            <h6>Home Applainces</h6>
            <span>Availability : <a href='#' style={{ color: '#38a626', textDecoration: 'none' }}>In Stock</a></span>
          </div>
          <div>
           
          </div>
        </article>


        

            <article className='item-title'>
              <div className='qty-list-div'>
                <span>Quantity : </span>
                <a href='#' className='minus' onClick={handleMinusClick} >-</a>
                <input type='text' value={quantity} readOnly ></input>
                <a href='#' className='plus' onClick={handlePlusClick}>+</a>
              </div>
            </article>

            <article className='item-title'>
                  <div className='attr-list-div'>                 
                    <span>Model No : MODEL1234</span>
                    <span>Sold by : Golden Tools Trading LLC</span>
                    <span>Expected delivery : <label style={{ color: '#38a626' }}>12-05-2023</label></span>
                  </div>
                </article>

          </div>
          <div  className='col-5'>

             
            <article className='item-title ' style={{width:'100%'}}>
           
              <div className='price-list-div'>
              <div className='like-btn'> 
              <a href='#'> <i className="fa fa-thumbs-up fa-2x"></i> </a>
               </div>
              <div className='was-price'><span>AED</span> 320.00</div>
                <h5 ><span>AED</span>275.00</h5>
              </div>

            </article>
          </div>
        </div>

           <div className='eligible-mob'>
             <div className='row'>

             {eligibleItem.map((item, index) => (
                     <div key={index} className='col-6 eligible-mob-col'>
                      <a key={item.id} href='#'>
                        <span>{item.text}</span>
                        <img
                          src={item.img_url}
                          alt={item.text}
                        />
                      </a>
                      </div>
                    ))}

                     

                     
             </div>
              
            </div>           


        <hr className='gtt-hr mb-2'></hr>
        <ItemNav />
        <div style={{ backgroundColor: '#EFF9FF' }}>
          <ShowHeadingList heading={'Related Items'} />
          <div className='product-carousel-xl' >
            <MultiCarousalSlid productData={productData} />
          </div>
        </div>

               

  
      </div>
              
      <div className='mob-addcart-div'>
                <a className='gtt-round-btn'>
                  <i className='fa fa-heart fa-2x'></i>  
                </a>  
                <a className='flat-btn'>
                  ADD TO CART
                </a>  
                <a onClick={() => navigate('/checkout')} className='gtt-round-btn'>
                  <i className="fa fa-cart-shopping fa-2x"></i>
                </a>                      
          </div>  
     
      </div>


      
    </div>
  )
}

export default Item