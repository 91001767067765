import React from 'react'
import OrderCard from '../Components/OrderCard'
import { ordermaster } from '../Data';
function TrackOrder() {

    
  return (
    <div>
         <div>
        <div className='myorder'>  
                                <div className='cart-head'>
                                 Track Order
                                  
                                </div>
        {ordermaster.map((item, index) => (
                                          <OrderCard  key={index}  order={item} trackorder={true}/>
                                    ))} 


            
        </div>

    </div>
    </div>
  )
}

export default TrackOrder