import React, { useState } from 'react'




import home from '../images/mobile/home.svg';
import favorite from '../images/mobile/favorite.svg';
import cart from '../images/mobile/cart.svg';
import profile from '../images/mobile/profile.svg';
import category from '../images/mobile/category.svg';

import homefill from '../images/mobile/home-fill.svg';
import favoritefill from '../images/mobile/favorite-fill.svg';
import cartfill from '../images/mobile/cart-fill.svg';
import profilefill from '../images/mobile/profile-fill.svg';
import categoryfill from '../images/mobile/category-fill.svg';
import { Link } from 'react-router-dom';


function Bottommenu() {

    const [activeMenuItem, setActiveMenuItem] = useState('home');
    const handleMenuClick = (menuItem) => {
        setActiveMenuItem(menuItem);
    };
    return (
        <div>


            <div className='mob-bottom-menu'>
                <div className='mob-menu'>
                    <Link to="/" onClick={() => handleMenuClick('home')} className={activeMenuItem === 'home' ? 'active' : ''}>
                        {activeMenuItem === 'home' ? (<img className="d-block w-100" src={homefill} alt="home" />) : (<img className="d-block w-100" src={home} alt="home" />)}
                        <span>Home</span>
                    </Link>
                    <Link to="/product" onClick={() => handleMenuClick('category')} className={activeMenuItem === 'category' ? 'active' : ''}>
                        {activeMenuItem === 'category' ? (<img className="d-block w-100" src={categoryfill} alt="category" />) : (<img className="d-block w-100  " src={category} alt='category' />)}
                        <span>Category</span>
                    </Link>
                    <Link to="/" onClick={() => handleMenuClick('favorite')} className={activeMenuItem === 'favorite' ? 'active' : ''}>
                        {activeMenuItem === 'favorite' ? (<img className="d-block w-100" src={favoritefill} alt="favorite" />) : (<img className="d-block w-100  " src={favorite} alt='favorite' />)}
                        <span>Favorites</span>
                    </Link>
                    <Link to="checkout" onClick={() => handleMenuClick('cart')} className={activeMenuItem === 'cart' ? 'active gtt-cart' : 'gtt-cart'}>
                        {activeMenuItem === 'cart' ? (<img className="d-block w-100" src={cartfill} alt="cart" />) : (<img className="d-block w-100  " src={cart} alt='cart' />)}
                       <span>Cart</span>   <span className='gtt-badge'>0</span>
                    </Link>
                    <Link to="login" onClick={() => handleMenuClick('profile')} className={activeMenuItem === 'profile' ? 'active' : ''}>
                        {activeMenuItem === 'profile' ? (<img className="d-block w-100" src={profilefill} alt="profile" />) : (<img className="d-block w-100  " src={profile} alt='profile' />)}
                        <span>Profile</span> 
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default Bottommenu