import React from 'react'

function Agreement({ showModal, closeModal }) {
  return (


    
    <div className={`modal ${showModal ? 'show' : ''}`} tabIndex="-1" role="dialog" style={{ display: showModal ? 'block' : 'none' }}>
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Terms & Conditions</h5>
            <a type="button" className="close" onClick={closeModal} aria-label="Close">
            <i className="fa fa-rectangle-xmark fa-2x"></i>
            </a>
          </div>
          <div className="modal-body">
                            <div className="term-box">

                            <ol>
                            <li>The items must be returned in original packaging with no scratches or damage.</li>
                            <li>All accessories and parts are packed and returned.</li>
                            <li>The product must be returned in its original packaging and must have all the original tags.</li>
                            <li>The product must be unused, unworn, unwashed and undamaged.</li>
                            <li>Please note that submitting this return request does not guarantee a refund, your refund will be approved or declined after a verification process based on our return policy.</li>
                            <li>In case the request is declined, you have to  collect item from store.</li>
                            <li>Return amount will be credit on your given accounts details.If you have enter  incorrect details,company not responsible for the same.</li>
                            </ol>   
                          
                            
                                                       
                        </div>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-sm btn-secondary" onClick={closeModal}>Close</button>
            <button type="button" className="btn btn-sm btn-primary" onClick={closeModal}>Agree</button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Agreement