import React, { useState } from 'react'

function Applycouponcode() {

    const [couponCode, setCouponCode] = useState('');
    const [discount, setDiscount] = useState(0);
    const [applied, setApplied] = useState(false);

    const applyCoupon = () => {
        // Here you might have some logic to validate and apply the coupon code.
        // For simplicity, let's assume the coupon code "SUMMER20" gives a 20% discount.
       
          setDiscount(0.2);
          setApplied(true);
       
      };
   const removeCoupon=()=>{
        setApplied(false);
      }
      
  return (
    <div>

<div className="input-group mb-3">
  <input type="text" className="form-control form-control-sm " placeholder="Coupon Code" aria-label="COUPON CODE" aria-describedby="basic-addon2"  value={couponCode} onChange={(e) => setCouponCode(e.target.value)}/>
  <div className="input-group-append">
     {applied ? (
        <a  href="#" className="input-group-text coupon"  onClick={removeCoupon} >Remove&nbsp;<i className="fa fa-trash-can"></i></a>
      ) : (
        <a href="#" className="input-group-text coupon"  onClick={applyCoupon} ><i className="fa fa-thumbs-up"></i>&nbsp;Apply</a>
      )}

    
  </div>
</div>
<p>Discount: {discount * 100}%</p>
        

    </div>
  )
}

export default Applycouponcode