import React, { useEffect, useState } from 'react'



import { profileorder,profileaccount } from '../Data';
import { Link, NavLink,Outlet, useNavigate } from 'react-router-dom';
function Profile() {  
  // const [isMobileView, setIsMobileView] = useState(false);
  
  // const [showDiv1, setShowDiv1] = useState(false);
  // const [showDiv2, setShowDiv2] = useState(false);

  // const toggleMobileView = () => {
  //   setIsMobileView(!isMobileView);
  // };

  // const checkIsMobileView = () => {
  //   setIsMobileView(window.innerWidth < 1200); // Adjust the breakpoint as needed
  //   console.log("============status========>"+isMobileView) 
  //   if(isMobileView)
  //   {
  //     setShowDiv1(true); 
  //     setShowDiv2(false); 
  //     console.log("============true========>"+isMobileView)
     
  //   }else{
  //     setShowDiv1(true); 
  //     setShowDiv2(true); 
  //     console.log("============false========>"+isMobileView)
  //   }   
  // };

    // useEffect(() => {

    // // Check the screen size when the component mounts and when it's resized
    // checkIsMobileView();
    // window.addEventListener('resize', checkIsMobileView);
    // // Clean up the event listener when the component unmounts
    // return () => {
    //   window.removeEventListener('resize', checkIsMobileView);
    // };

    // }, []);
     
     const navigate = useNavigate();
    const [activeProfileIndex, setactiveProfileIndex] = useState('1');


    // const toggleDiv1 = () => {
    //   if(isMobileView)
    //   {
    //   setShowDiv1(!showDiv1);
    //   setShowDiv2(false); 
    //   }// Hide div2 when showing div1
    // };
  
    // const toggleDiv2 = () => {
    //   if(isMobileView)
    //   {
    //   setShowDiv2(!showDiv2);
    //   setShowDiv1(false); 
    //   }// Hide div1 when showing div2
    // };


  return (
    <div>

            <div className='row pt-2'>
                <div className='col-md-4 col-sm-12'>


                    <div className='user-menu '>

                    <div className='user-profile'> 
                        <div className='mr-2'> <i className="fa fa-circle-user fa-4x"></i></div>
                        <div>  
                        <p>Hello</p>
                            <h6>NISHAD MOIDEENKUTTY </h6>                          
                        </div>                        
                    </div>
                    <div className='user-profile-menu'> 
                                    <h6>Orders</h6>
                                    {profileorder.map((item, index) => (
                                         <Link
                                          to={item.url}
                                         key={index}
                                         className={item.value === activeProfileIndex ? 'activemenu' : ''}  onClick={() => {setactiveProfileIndex(item.value); }}
                                         >
                                         {item.label} <span ><i className="fa fa-angle-right"></i></span> 
                                       </Link>
                                    ))} 
                    </div>
                     <div className='user-profile-menu'> 
                                    <h6>Account Settings</h6>
                                      {profileaccount.map((item, index) => (
                                          <Link   to={item.url}  key={index} className={item.value === activeProfileIndex ? 'activemenu' : ''} 
                                             onClick={() => {setactiveProfileIndex(item.value);}}>
                                            {item.label} <span ><i className="fa fa-angle-right"></i></span> 
                                           </Link>
                                    ))} 

                    </div>
                </div>


               


               </div>
                                   
               <div className='col-md-8 col-sm-12'>
                     <Outlet/>
               </div>

            </div>


    </div>
  )
}

export default Profile