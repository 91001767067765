import React from 'react'

import { cartItems,productData} from '../Data';
import CartProductCard from '../Components/CartProductCard';
import { useNavigate } from 'react-router-dom';
function OrderComplete() {
    const navigate = useNavigate();
  return (
    <div>
        <div className='order-complete-main'>
            <div className='order-complete-main-head'>
            <i className="fa fa-circle-check fa-9x"></i>
             <h5> Thanks for your order</h5>
             <a  className="btn btn-sm   btn-block "  >CONTINUE SHOPPING</a>
            </div>
            <div className='order-complete-main-dtls'>
                <div className='row'>
                <div className='col-md-3'></div>
                    <div className='col-md-3'>
                            <h6>Shipping Address</h6>

                             <address>
                                <p> Nishad Moideenkutty </p> 
                                <p>  Goldentool Tradmin LLC </p> 
                                <p>   PO BOX 42520 </p>                                                                
                             </address>
                    </div>
                    <div className='col-md-3'>
                                <h6>Summary</h6>
                            
                             <address>
                                <p> Order No : GTT000253 </p> 
                                <p>  Order Date : 25-05-2023 </p> 
                                <p> Expected Delivery : 228-03-2023 </p>  
                                                                                              
                             </address>
                             <h6> Order Items :10 nos</h6>  
                             <h6> Order Amount :AED 2057.00</h6>  
                    </div>
                    <div className='col-md-3'></div>
                </div>


                <div className='row mb-5 mt-2'>
                    <div className='col-md-12 col-sm-12'>
                            <div className='cart-head'>
                                 Ordered Items
                            </div>
                            <div>
                                 {cartItems.map((item, index) => (
                                        <CartProductCard  key={index} item={item} favorateflg={true} trashflg={true}/>
                                 ))}
                            </div>


                    </div>
                  
                </div>


            </div>
            <div className='order-complete-footer'>
            <a  className="btn btn-sm  btn-lg btn-block addcart " onClick={()=>{navigate(-1);}} >View Your Order</a>
            </div>
        </div>
    </div>
  )
}

export default OrderComplete