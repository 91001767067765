import React, { useState, useEffect, useRef } from 'react'
import imageSrc from '../images/company-logo-2.png';
import '@fortawesome/fontawesome-free/css/all.min.css';

//import { catgoryData } from '../Data';
import { show_category } from '../ApiURL';
import { NavLink, Link, useNavigate, Navigate } from 'react-router-dom';
import OffCanvasMob from './OffCanvasMob';


function Mainhead() {
  
  
  const navigate = useNavigate();
  const [activeMenuIndex, setActiveMenuIndex] = useState(null);
  const [catgoryData, setCatgoryData] = useState([])
  const handleMenuItemClick = (index, catName) => {
    setActiveMenuIndex(activeMenuIndex === index ? null : index);
    navigate('/product/' + catName);
    setActiveMenuIndex(null);
    setShowOffCatMenu(false);
  };
  const handleNavLinkClick = () => {
    setActiveMenuIndex(null); // Hide the submenu when NavLink is clicked

  };


  const [searchString, setSearchString] = useState();
  const searchBoxRef = useRef();
  const [isSearchBoxVisible, setIsSearchBoxVisible] = useState(false);
  const toggleSearchBox = () => {
    setIsSearchBoxVisible(!isSearchBoxVisible);
  };
  const handleBodyClick = (event) => {
    if (!event.target.classList.contains('search-form-control')) {
      setIsSearchBoxVisible(false);

    }
  };
  const handleKeyPress = event => {
    if (event.key === 'Enter') {
      // Navigate to another page when Enter is pressed
      navigate('/product');
    }
  };

  const [showOffCatMenu, setShowOffCatMenu] = useState(false);
  const [showOffCanvas, setShowOffCanvas] = useState(false);
  const [isOffCanvasOpen, setIsOffCanvasOpen] = useState(false);
  const barsIconRef = useRef(null);
  const offCanvasRef = useRef(null); // Initialize with null

  const handleMouseEnter = () => {
    setShowOffCatMenu(true)
  };


  const toggleOffCanvas = () => {
    setShowOffCanvas(!showOffCanvas);
    setIsOffCanvasOpen(!isOffCanvasOpen);
  };
  const handleClickOutside = (event) => {
    if (offCanvasRef.current && !offCanvasRef.current.contains(event.target) && event.target !== barsIconRef.current) {
      setShowOffCanvas(false);
      setIsOffCanvasOpen(false);
    }
  };
  useEffect(() => {
    categoryHomeFetch();
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);


  const categoryHomeFetch = () => {
  
    fetch(show_category)
        .then((response) => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          console.log(response);
          return response.json();
        })
        .then((data) => {
          // Process the JSON data
          console.log(data);
          setCatgoryData(data)
        })
        .catch((error) => {
          console.error('Error:', error);
        });
  }

  return (
    <div>
      <div className='full-bg-head'>
        <div className='masterhead'>
          <div className="grid-item"><img className="companylogo" src={imageSrc} alt="Image Description" /></div>
          <div className="grid-item">
            <div className='item icon-name'>
              <a href='#' onClick={() => navigate('/')} > <i className="fas fa-home"></i> <h5>Home</h5></a>
              <a href='#' onClick={() => navigate('/favorites')} > <i className="fas fa-heart"></i> <h5>Favorites</h5></a>
            </div>
          </div>
          <div className="grid-item">
            <div className='item icon-name'>
              <div className="input-group input-group-sm mb-3">
                <input type="text" className="form-control form-control-sm" value={searchString} onChange={(e) => setSearchString(e.target.value)} />
                <button onClick={() => navigate('/product')} className="btn gtt-searchbtn" type="button" id="button-addon2"><i className="fa fa-search" aria-hidden="true"></i></button>
              </div>
            </div>
          </div>
          <div className="grid-item"> <div className='item icon-name'>
            <a href='#' className='gtt-cart' onClick={() => navigate('/checkout')}> <i className="fas fa-cart-shopping"></i><h5>My orders</h5> <span className='gtt-badge'>0</span></a>
            <a href='#' onClick={() => navigate('/login')} > <i className="fas fa-user"></i><h5>Sign In</h5></a>
            <button href='#' onClick={() => navigate('/login')} className='btn btn-sm btn-primary'> Bulk Order</button>
          </div>
          </div>
        </div>
        <div className='maincategory-head'>
        <div className='maincategory-head-sub'>         
           {catgoryData.map((category, index) => (
               <NavLink key={index} to={category.link_url} onClick={handleNavLinkClick} >
               {category.catgory_name}
               </NavLink>              
              ))}
          </div>
        </div>
      </div>
      <div className='mob-masterhead'>
        <div className='item mob-icon'>
          <Link to='#' onClick={() => {
            navigate(-1);
          }}><i className="fa fa-angle-left" aria-hidden="true"></i></Link>
        </div>
        <div className="item mob-icon"> <Link to='/'><img className="companylogo" src={imageSrc} alt="Image Description" /> </Link>  </div>
        <div className="item mob-icon">
          <a href='#' onClick={toggleSearchBox} ><i className="fa fa-search" aria-hidden="true"></i></a>
        </div>
        <div className="item mob-icon open-button"><a href='#' onClick={toggleOffCanvas} ref={barsIconRef}><i className="fa fa-bars" aria-hidden="true"></i></a>

        </div>
      </div>
      {isSearchBoxVisible ?

        (<div ref={searchBoxRef} onClick={handleBodyClick} className='input-container-mob animate__animated animate__fadeIn'  >
          <div className='input-group-gtt'>
            <input type='text' className='search-form-control' value={searchString} onChange={(e) => setSearchString(e.target.value)} onKeyPress={handleKeyPress}></input>
            <span className="input-group-addon"><i className="fa fa-close fa-2x" aria-hidden="true"></i></span>
          </div>
        </div>) : null
      }
      {showOffCanvas ? (
        <OffCanvasMob isOpen={isOffCanvasOpen} toggleMenu={toggleOffCanvas} offCanvasRef={offCanvasRef} />
      ) : null}
    </div>

  )
}
export default Mainhead