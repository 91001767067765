import React, { useState } from 'react'

function AccountRegister({ updateStep }) {

    const [showPassword, setShowPassword] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const handleSomeAction = () => {
        // Update the current step by calling the updateStep function
        updateStep(1); // This will set the step to 1 (or any other step you want)
    };

    return (
        <div>

            <div className='account-reg'>
                <div className="checkout-head" >
                    <h6>Account details</h6>
                </div>

                <form className="row g-3 needs-validation" novalidate>

        
                     
              

                    <div className="col-md-6">
                        <label  className="form-label">First name</label>
                        <input type="text" className="form-control form-control-sm" id="validationCustom01" value="" required />

                    </div>

                    <div className="col-md-6">
                        <label for="validationCustom01" className="form-label">Last name</label>
                        <input type="text" className="form-control form-control-sm" id="validationCustom01" value="" required />

                    </div>
                    <div className="col-md-12">
                        <label for="validationCustom01" className="form-label">Email</label>
                        <input type="text" className="form-control form-control-sm" id="validationCustom01" value="" required />

                    </div>
                    <div className="col-md-12">
                        <label for="validationCustom01" className="form-label">Mobile Number</label>
                        <input type="text" className="form-control form-control-sm" id="validationCustom01" value="" required />

                    </div>
                    <div className="col-md-6 ">
                        <label for="validationCustom01" className="form-label">Password</label>

                        <div className="input-group has-validation">

                            <input type={showPassword ? 'text' : 'password'} className="form-control form-control-sm" id="validationCustomUsername" aria-describedby="inputGroupPrepend" required />
                            <div className="invalid-feedback">
                                Please choose a username.
                            </div>
                            <span className="input-group-text" onClick={togglePasswordVisibility} >{showPassword ? <i className="fa fa-eye"></i> : <i className="fa fa-eye-slash"></i>} </span>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <label for="validationCustom01" className="form-label">Confirm Password</label>
                        <input type="password" className="form-control form-control-sm" id="validationCustom01" value="" required />

                    </div>

                    <div className="col-md-12">

                        <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                            <button onClick={handleSomeAction} className="btn btn-sm btn-primary me-md-2" type="button">SAVE & CONTINUE</button>
                        </div>

                    </div>


                </form>

            </div>
        </div>
    )
}

export default AccountRegister