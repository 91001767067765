import React from 'react'

import address from '../images/footer/footer-address.svg';
import mail from '../images/footer/footer-mail.svg';
import message from '../images/footer/footer-message.svg';
import phone from '../images/footer/footer-phone.svg';



function ContactUs() {
  return (
    <div className='pt-3 contactus-div '>
                             <div className='cart-head'>
                               Contact Us                                  
                             </div>
                     <div className='row p-2'>
                        <div className='col-md-6 col-sm-12'>
                             <div className="contactus">
                                <div className='address-div'>
                                    <a  className="inline-link"><img src={address} alt="" /><span> Dubai Investment Park-1, Dubai, UAE</span></a>
                                    <a  className="inline-link"><img src={mail} alt="" /><span>helpdesk@goldentoolsuae.ae</span></a>
                                    <a  className="inline-link"><img src={phone} alt="" /><span>+971 4 2238240    +971 4 2722128</span></a>
                                    <a  className="inline-link"><img src={message} alt="" /><span>+971 565044025</span></a>
                                 </div>                            
                             </div>
                        </div>
                        <div className='col-md-6 col-sm-12'>
                            
                        <div className='card return-card mb-4'>

                        <form className="row g-3 needs-validation" >

                                <div className="col-md-12">  
                                     <label  className="form-label">Full Name</label>
                                    <input type="text" className="form-control form-control-sm" id="validationCustom01" value="" required />
                                </div>
                                <div className="col-md-6">  
                                     <label  className="form-label">Mobile Number</label>
                                    <input type="text" className="form-control form-control-sm" id="validationCustom01" value="" required />
                                </div>
                                <div className="col-md-6">  
                                     <label  className="form-label">Email</label>
                                    <input type="text" className="form-control form-control-sm" id="validationCustom01" value="" required />
                                </div>
                                <div className="col-md-12">  
                                     <label  className="form-label">Subject</label>
                                    <input type="text" className="form-control form-control-sm" id="validationCustom01" value="" required />
                                </div>
                                <div className="col-md-12">
                                    <label  className="form-label">Message</label>
                                    <textarea className="form-control" id="exampleFormControlTextarea1" rows="3"></textarea>
                                
                                </div>
                        </form>
                        </div>
                            
                             </div>
                             </div>


                           
                          
    </div>
  )
}

export default ContactUs