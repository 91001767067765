import React from 'react'
import CarousalPageCategory from '../Components/CarousalPageCategory'
import { brandImages } from '../Data';
function Brand() {
  return (
    <div>

        <CarousalPageCategory/>

        <div className='row'>

        {brandImages.map((image, index) => (
             <div className='col-md-3'>
                     <div key={index} className='brand-img-box-2' >
                        <a href='#'>
                            <div className="catgory-ellipse" >
                                {image ? (
                                    <img
                                        className="d-block w-100  "
                                        src={image.img_url}
                                        alt="" />
                                ) : null}
                            </div>
                            
                        </a>
                    </div>
                    </div>    
                ))}
                
        
            
        </div>



    </div>
  )
}

export default Brand