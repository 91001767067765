import React, { useState } from 'react'

import { useNavigate } from 'react-router-dom';
function CartProductCard(props) {

    const item=props.item
  const navigate = useNavigate(); 
    const [quantity, setQuantity] = useState(parseInt(item.itm_qty));

  const handleMinusClick = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1);
    }
  };

  const handlePlusClick = () => {
    setQuantity(quantity + 1);
  };


  return (
    <div>
    <div className='cart-card' >      
              <center>
                <img className="" src={item.img_url} alt="" />
                </center>
                <div className='cart-item-spec' onClick={() => navigate('/item/' + item.id)}>
                     <p>{item.brand_name}</p>
                     <h5>{item.itm_title}</h5>
                     <p>{item.itm_name}</p>
                      { item.freedelivery_flg=='Y' ?<p><span className="highlight-green">Free Delivery</span> Available </p>:null}
                      { item.expected_delivery ?<p><span className="highlight-green">Expected Delivery</span>{item.expected_delivery}</p>:null}
                </div>
                {
                  !props.favorateflg?<div className='cart-item-qty'>

                  <article className='item-title'>
                    <div className='qty-list-div'>
                      <span>Quantity : </span>
                      <a href='#' className='minus' onClick={handleMinusClick} >-</a>
                      <input type='text' value={quantity} readOnly ></input>
                      <a href='#' className='plus' onClick={handlePlusClick}>+</a>
                    </div>
                  </article>
  
                  </div>  :null
                  
                  
                }
                 {
                  props.orderitem?
                          <div className='cart-item-qty'>
                          <article className='item-title'>
                                              <div className='qty-list-div'>
                                                <span>Quantity : {item.itm_qty}</span>
                                                
                                              </div>
                          </article></div>  :null
                }
                
                
                <div className='cart-item-price'>
                    <article className='item-title'>
                            <div className='price-list-div'>
                            <h5><span>AED</span>275.00</h5>
                            <div className='was-price'><span>AED</span> 320.00
                            </div>
                            {!props.trashflg?<div className='cart-item-remove '>
                            <p>Remove : <a href='#'><i className="fa fa-trash-can"></i></a></p>
                            </div>:null}
                            {props.returnflg?<div className='cart-item-remove '>
                            <p>Click here : <a href='#'  onClick={() => navigate('/profile/returnitem/' + item.id)} >Return</a></p>
                            </div>:null}
                         </div>                                                                                                          
                    </article>

                </div>

    </div>
    <div className='cart-card-mob' >    
          <img className="" src={item.img_url} alt="" />
                <div className='cart-item-spec' onClick={() => navigate('/item/' + item.id)}>
                     <p>{item.brand_name}</p>
                     <h5>{item.itm_title}</h5>
                     <p>{item.itm_name}</p>
                      { item.freedelivery_flg=='Y' ?<p><span className="highlight-green">Free Delivery</span> Available </p>:null}
                      { item.expected_delivery ?<p><span className="highlight-green">Expected Delivery</span>{item.expected_delivery}</p>:null}
                </div>
               
                <div className='cart-item-price'>
                  
                    <article className='item-title'>
                  
                            <div className='price-list-div'>
                              
                            <span>Quantity : {item.itm_qty}</span>
                            <h5><span>AED</span>275.00</h5>

                            <div className='was-price'><span>AED</span>320.00</div>
                            {!props.trashflg?<div className='cart-item-remove'>
                               Remove : <a href='#'><i className="fa fa-trash-can"></i></a>
                            </div>:null}
                            {props.returnflg?<div className='cart-item-return' >
                            <p><a href='#'  onClick={() => navigate('/profile/returnitem/' + item.id)} >Return</a></p>
                            </div>:null}           
                         </div>  
                                                                                                                  
                    </article>

                </div>

    </div>
    </div>
  )
}

export default CartProductCard