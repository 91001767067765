import React, { useState } from 'react'
import { useEffect } from 'react';

import { useNavigate } from 'react-router-dom';

function ProductCard({itemsingle}) {

  const [item, setItem] = useState(null);
  const [isClicked, setIsClicked] = useState(false);
  const toggleHeartColor = () => {
    setIsClicked(!isClicked);
  };

  const navigate = useNavigate();

  useEffect(() => {
    // Set the initial value of 'item' when 'itemsingle' changes
    setItem(itemsingle);

  }, [itemsingle]);




  return (
    <div className='product-card-gtt' onClick={() => navigate('/item/' + item.id)}>

      <div className='product-body-gtt'>

     <a href="#"  className="addcart-icon">               
     <i className="fa-solid fa-cart-plus"></i>
    </a>

      <a href="#"  onClick={toggleHeartColor} className={`icon ${isClicked ? 'favorateselect' : 'favoratenormal'}`}>
          
            {isClicked ?<i className="fa-solid fa-heart"></i> :<i className="fa-regular fa-heart"></i>}
          
      </a>
        
        <img className="d-block w-100 img-product " src={require('../images/img1.png')} alt="" />

        <div className='product-details-gtt'>
        
          <h6 className="product-brand-text">Brand</h6>
          <h3 className="product-title">Product Name Name NameNameNameName</h3>
          <p className="product-description">
            STAND MIXER 1100W KITCHEN MACHINE | ELECTRIC MIXER | 7L STAINLESS STEEL MIXING BOWL WITH LID | 6 SPEEDS | SAFTY SWITCH | DOUGH HOOK- BEATER- WIRE WHIP VE-192
          </p>

        </div>

        <div className='product-attribute-gtt'>
          <div className='gtt-facility'>
            <p className='gtt-waranty'>1 Years <br/><span className="warranty-tag">Warranty </span> </p>
            <p className='gtt-freedelivery' >Free Delivery</p>
          </div>
          <div className='price-tag'>
            <h6 className='was-price'>328.00</h6>
            <h4 className='current-price'> <span className="currency-tag">AED</span>273.00</h4>
            {/* <h6> <span className='discount-tag'>53% Off</span></h6> */}
          </div>          
        </div>

       
      </div>

    </div>
  )
}

export default ProductCard