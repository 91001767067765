import React from 'react'

function LeftPanePriceRange() {
  return (
    <div className='leftpane-price-range'>
        <h6>Price Range</h6>
        <div className='row'>
             <div className='col-6'>             
                 <label className="form-label">Min</label>
                  <input className="form-control form-control-sm" type="text" id="min_price" placeholder='0'/>
             </div>
             <div className='col-6'>
             <label  className="form-label">Max</label>
                  <input className="form-control form-control-sm" type="text" id="max_price" maxLength={7}  placeholder='99999'/>
             </div>
             <div className='col-12 text-center mt-2'> 
                    <button  className='btn btn-outline-primary btn-sm '>
                      Apply
                    </button>
             </div>
        </div>
    </div>
  )
}

export default LeftPanePriceRange
