import React from 'react'

function BankDtls(props) {


  return (
    <div>

<div className='cart-head '>
                               Bank Details
                                  
                                </div>

                                <div className='card return-card'>
                                <form className="row g-3">
                                        <div className="col-md-6">
                                            <label  className="form-label">IBAN No</label>
                                            <input type="text" className="form-control form-control-sm" id="validationDefault01"  />
                                        </div>
                                        <div className="col-md-6">
                                            <label className="form-label">Bank Name</label>
                                            <input type="text" className="form-control form-control-sm" id="validationDefault01"  />
                                        </div>
                                        <div className="col-md-6">
                                            <label  className="form-label">Account Number</label>
                                            <input type="text" className="form-control form-control-sm" id="validationDefault01" />
                                        </div>
                                        <div className="col-md-6">
                                            <label  className="form-label">Swift Code</label>
                                            <input type="text" className="form-control form-control-sm" id="validationDefault01"  />
                                        </div>
                                        
                                        {!props.returnFlg && <div className="d-grid gap-2 d-md-flex justify-content-md-end">
                                                <button className="btn btn-sm btn-primary me-md-2" type="button">SAVE </button>
                                                </div>  }      
                                </form>
                                </div>
    </div>
  )
}

export default BankDtls