import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom';

function Login() {
    const [showPassword, setShowPassword] = useState(false);
    const navigate = useNavigate();

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };
  return (
    <div>
         <div className='login'>
                <div className='login-div'>
                <h6>Sign in to your account</h6>

                 <form className="row g-3 needs-validation mt-4" novalidate>

                    <div className="col-md-12">
                        <label for="validationCustom01" className="form-label">Email or Phone</label>
                        <input type="text" className="form-control form-control-sm" id="validationCustom01" value="" required />

                    </div>

                    <div className="col-md-12">
                        <label for="validationCustom01" className="form-label">Password</label>
                        <div className="input-group has-validation">

                            <input type={showPassword ? 'text' : 'password'} className="form-control form-control-sm" id="validationCustomUsername" aria-describedby="inputGroupPrepend" required />
                            <div className="invalid-feedback">
                                Please choose a username.
                            </div>
                            <span className="input-group-text" onClick={togglePasswordVisibility} >{showPassword ? <i className="fa fa-eye"></i> : <i className="fa fa-eye-slash"></i>} </span>
                            </div>
                                                    <span >Use 8 or more characters with a mix of letters, numbers & symbols</span>   
                    </div>

                    <div className="col-md-6"></div>
                    <div className="col-md-6 text-end">
                       <a href='#' className="forgotbtn" > Forgot Password</a>
                    </div>

                    <div className="col-md-12"></div>
                    <div className="col-md-6">
                      <a href='#' onClick={() => navigate('/createaccount')} className='createbtn text-black'> Create an account</a>
                    </div>
                    <div className="col-md-6 text-end">
                        <a href='#' onClick={() => navigate('/profile')}   className='signinbtn btn btn-sm'> SIGN IN</a>
                    </div>
                    
                 </form>
                </div>

         </div>

    </div>
  )
}

export default Login