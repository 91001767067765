import React from 'react'

import { sociallink,catgoryData,quickLink,resources } from '../Data';

import address from '../images/footer/footer-address.svg';
import mail from '../images/footer/footer-mail.svg';
import message from '../images/footer/footer-message.svg';
import phone from '../images/footer/footer-phone.svg';
import BrandBottom from './BrandBottom';

function PageBottom() {
  return (
    <div className='gtt-page-bottom'> 
            <div className="footer-brand">
                <BrandBottom/>
            </div>
            <footer>

           

                    <div className="footer-container">




                        <div className="content_1">
                            <h4>Categories</h4>
                           
                            {catgoryData.map((category, index) => (                               
                                 <a href="#category" key={index}>{category.cat_name}</a>                             
                            ))}
                            
                        </div>
                        <div className="content_2">
                           <h4>Quik Links</h4>
                           {quickLink.map((qlink, index) => (                               
                                 <a href="#" key={index}>{qlink.link_name}</a>                             
                            ))}
                        </div>
                        <div className="content_3">
                        <h4>Resources</h4>
                        {resources.map((resource, index) => (                               
                                 <a href="#" key={index}>{resource.link_name}</a>                             
                            ))}
                        </div>
                        <div className="content_4">
                            <h4>Contact</h4>
                             <div className='address-div'>
                                    <a  className="inline-link"><img src={address} alt="" /><span> Dubai Investment Park-1, Dubai, UAE</span></a>
                                    <a  className="inline-link"><img src={mail} alt="" /><span>helpdesk@goldentoolsuae.ae</span></a>
                                    <a  className="inline-link"><img src={phone} alt="" /><span>+971 4 2238240    +971 4 2722128</span></a>
                                    <a  className="inline-link"><img src={message} alt="" /><span>+971 565044025</span></a>

                             </div>

                             <h4>Follow us</h4>

                             <div className='follow-div'>
                              
                                     {sociallink.map((social, index) => (    

                                        <a className='inline-link' key={index} ><img src={social.img_url} alt="" /></a>
                                    ))}
                                                                    
                                  
                             </div>
                        </div>
                    </div>
                 
                    <div className="footer-design">
                                <div className="footer-terms">
                                    <a  className="inline-link"><span> Privacy Policy</span></a>
                                    <a  className="inline-link"><span>Terms of Use</span></a>
                                    <a  className="inline-link"><span>Sales and Refunds</span></a>
                                    <a  className="inline-link"><span>Legal</span></a>
                                    <a  className="inline-link"><span>Site Map</span></a>
                                    </div>
                        <div className="footer-design-txt">
                            <p>© 2023 All Rights Reserved www.goldentools.ae</p>
                           
                        </div>
                    </div>


                  
                </footer>


          
    </div>
  )
}

export default PageBottom