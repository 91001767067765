import React, { useEffect } from 'react'
import LeftSidePane from '../Components/LeftSidePane'
import CenterPane from '../Components/CenterPane'

import { FilterProvider } from '../ContextProvider/FilterContext';
import CarousalPageCategory from '../Components/CarousalPageCategory';
import SubCategoryList from '../Components/SubCategoryList';
import { useParams } from 'react-router-dom';
import { subcatgoryData } from '../Data';
function SearchList() {


  const { category } = useParams();
  const filteredData = subcatgoryData.filter(item => {
    if (category) {
      return removeSpecialCharacters(item.parent_name).toLowerCase() === removeSpecialCharacters(category).toLowerCase();
    }
    return false;
  });
  
  function removeSpecialCharacters(str) {
    // Replace non-alphanumeric characters with empty string

    return str.replace(/[^a-zA-Z0-9]/g, '');
  }
    
  return (


     <FilterProvider>
        <CarousalPageCategory/>

          <div className='row'>
              <div className='col-12'>
              {(category &&  filteredData.length > 0) ? <SubCategoryList category={category} filteredData={filteredData} /> : null}
              </div>  
          </div>
         
          <div className='row'>
                <div className='col-lg-3 left-side-pane d-md-block '>
                  <LeftSidePane/>
              </div>
              <div className='col-lg-9 col-md-12  d-md-block'>
                  <CenterPane/>                  
              </div>
          </div>
    </FilterProvider>
  )
}

export default SearchList